import React from 'react'

import styled, {css} from 'styled-components'
import {palette} from 'styled-tools'

const textButtonStyles = css`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${palette('ui50Solid')};
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid ${palette('ui50Solid')};
  padding: 0;
  cursor: pointer;
  background: none;
  transition: color 0.15s;

  &:hover {
    color: ${palette('ui80Solid')};
  }
`

const Anchor = styled.a`
  ${textButtonStyles}
`

const Btn = styled.button`
  ${textButtonStyles}
`

export const TextButton = ({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <Btn {...props}>{children}</Btn>
)

const TextAnchor = React.forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>(function TextAnchor(
  {children, ...props}: React.AnchorHTMLAttributes<HTMLAnchorElement>,
  ref,
) {
  return (
    <Anchor {...props} ref={ref}>
      {children}
    </Anchor>
  )
})

export default TextAnchor
