import getConfig from 'next/config'

export const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: getConfig()?.publicRuntimeConfig?.cognitoUserPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId:
      getConfig()?.publicRuntimeConfig?.cognitoClientId,
    cookieStorage: getConfig()?.publicRuntimeConfig?.domain
      ? {
          domain: getConfig()?.publicRuntimeConfig?.domain,
          secure: process.env.NODE_ENV !== 'development',
        }
      : undefined,
  },
}
