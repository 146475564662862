import React from 'react'

import {useSpring} from '@react-spring/web'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'

import {LoadingBar, LoadingContainer} from './Button'
import {iconButtonStyles} from './buttonStyles'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  accentColor?: 'green' | 'lightBlue' | 'pink' | 'blue'
  large?: boolean
  backgroundColor?: string
  icon?: RemixiconReactIconComponentType
  loading?: boolean
  outlined?: boolean
  borderRadius?: boolean
  hoverBackgroundColor?: string
}

const Btn = styled.button<Props>`
  ${(p) =>
    iconButtonStyles({
      accentColor: p.accentColor,
      large: p.large,
      backgroundColor: p.backgroundColor,
      borderRadius: p.borderRadius,
      hoverBackgroundColor: p.hoverBackgroundColor,
    })}
`

export default function IconButton({
  icon: Icon,
  loading,
  disabled,
  ...props
}: Props): JSX.Element {
  const loadingSpring = useSpring({
    from: {transform: 'translateX(-120%)'},
    to: {transform: 'translateX(20%)'},
    config: {mass: 1, tension: 16, friction: 0},
  })

  return (
    <Btn
      disabled={disabled || loading}
      {...props}
    >
      <Icon />
      <LoadingContainer style={{opacity: loading ? 1 : 0}}>
        <LoadingBar
          style={loadingSpring}
          accentcolor={props.accentColor}
        />
      </LoadingContainer>
    </Btn>
  )
}
