import React from 'react'

import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'

interface Props {
  label?: string
  checked: boolean
  onToggle: (checked: boolean) => void
  ariaLabel?: string
}

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 0;
`

const Text = styled.div`
  font-weight: 500;
  font-size: 1rem;
  color: ${palette('blue')};
  line-height: 1;
  margin-left: 12px;
  text-align: left;
`

const Switch = styled.div`
  position: relative;
  width: 46px;
  min-width: 46px;
  height: 24px;
  border-radius: 12px;
  background-color: ${palette('ui10Solid')};
  box-shadow: inset 0px 0px 2.7px rgba(51, 62, 117, 0.1);
  transition: background-color 0.15s;
`

const SwitchBubble = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${palette('white')};
  transition: left 0.15s ease-out;
`

export default function Toggle({
  label,
  checked = false,
  onToggle,
  ariaLabel = 'Toggle',
}: Props): JSX.Element {
  return (
    <Wrapper
      type="button"
      onClick={() => onToggle(!checked)}
      aria-label={ariaLabel}
    >
      <Switch
        style={
          checked
            ? {
                backgroundColor: styledTheme.palette.lightBlue,
                borderColor: styledTheme.palette.lightBlue,
              }
            : {}
        }
      >
        <SwitchBubble style={{left: checked ? '24px' : '2px'}} />
      </Switch>
      {label && <Text>{label}</Text>}
    </Wrapper>
  )
}
