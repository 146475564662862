import React from 'react'

import times from 'lodash/times'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'

interface Props {
  currentStep?: number
  steps: number
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Step = styled.div`
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background-color: ${palette('light')};
  position: relative;
`

const ProgressLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background-color: ${palette('green')};
  transition: width 0.2s ease-in-out;
  z-index: 1;
`

const StepDot = styled.div`
  background-color: ${palette('ui20Solid')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;
  margin: 18px;
  border-radius: 50%;
  position: relative;
  z-index: 1;

  .remixicon-icon {
    position: relative;
    z-index: 2;
  }
`

const ProgressDot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${palette('green')};
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
  z-index: 1;
`

export default function ProgressSteps({
  currentStep,
  steps,
}: Props): JSX.Element {
  return (
    <Wrapper>
      {times(steps, (step: number) => (
        <Step key={step}>
          <ProgressLine style={step < currentStep ? {width: '100%'} : {}} />
          <StepDot>
            <CheckLineIcon size={16} color={styledTheme.palette.white} />
            <ProgressDot
              style={step < currentStep ? {transform: 'scale(1)'} : {}}
            />
          </StepDot>
        </Step>
      ))}
    </Wrapper>
  )
}
