import {isValid, info, clean, format} from 'kennitala'
import truncate from 'lodash/truncate'
import words from 'lodash/words'

// Returns the first letter of each word in a string as an uppercase string.
export function getAcronym(name: string): string {
  return words(name)
    .map((word) => word[0]?.toUpperCase())
    .join('')
}

// Returns a string with the first name and last name truncated to the provided
// length. If the first name is longer than the provided length, it will
// return as an acronym.
export function wordTruncateOrAcronym(str: string, len = 20): string {
  const val = words(str).join(' ')

  if (val.split(' ', 1)[0]?.length > len) {
    return getAcronym(val)
  }
  return truncate(val, {length: 13, separator: ' ', omission: ''})
}

export function unidecode(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

// TODO: Test
export function formatTime(time?: string): string {
  if (!time) {
    return '00:00'
  }

  return time.substring(0, time.lastIndexOf(':00'))
}

export function removeSpaces(str: string): string {
  return str.replace(/\s/g, '')
}

// Formats a phonenumber to the format 000 0000
// And removes icelandic country code
// When the number is less than 7 digits it will return the number as is
export function formatPhoneNumber(phone?: string): string {
  if (!phone) {
    return ''
  }
  const trimmed = phone.replace('+354', '')

  if (trimmed.length < 7) {
    return trimmed
  }

  return `${trimmed.substring(0, 3)} ${trimmed.substring(3, trimmed.length)}`
}

// Removes country code and any whitespace from a phone number
export function parsePhoneNumberNoPrefix(phone?: string): string {
  if (!phone) {
    return ''
  }

  const trimmed = removeSpaces(phone)

  if (trimmed.startsWith('+354')) {
    return trimmed.slice(4)
  }

  if (trimmed.startsWith('00354')) {
    return trimmed.slice(5)
  }

  return trimmed
}

// Removes whitespace from a phone number and adds country code
export function parsePhoneNumber(phone?: string): string {
  return phone ? `+354${parsePhoneNumberNoPrefix(phone)}` : ''
}

export function parseBase64Json(cookie: string) {
  if (!cookie) return undefined
  return JSON.parse(Buffer.from(cookie, 'base64').toString())
}

export const formatBusinessName = (
  name?: string,
  ssn?: string,
  truncate?: boolean,
) => {
  if (!name || !ssn) return ''

  const isPerson = ssnInfo(ssn).type === 'person'

  const truncateName = truncate ? wordTruncateOrAcronym(name) : name

  return isPerson ? `${truncateName} (fyrirtæki)` : truncateName
}

export const removeInches = (str?: string) =>
  str ? str.replaceAll(/"/g, '') : ''

// SSN functions
export const cleanSSN = (ssn?: string) => (ssn ? clean(ssn) : '')
export const formatSSN = (ssn?: string, spacer?: string) =>
  ssn ? format(ssn, spacer) : ''
export const ssnInfo = (ssn: string | number) => info(ssn)
export const isValidSsn = (ssn: string) => {
  return /(9|0)$/.test(ssn) && isValid(ssn)
}
