import React from 'react'

import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '../../themes'

const btnStyles = css`
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};
  display: flex;
  align-items: center;
  padding: 5px 0;
  background: transparent;
  border: 0;
  cursor: pointer;

  .remixicon-icon {
    margin-right: 10px;
    transition: transform 0.15s ease-out;
  }

  &:hover {
    .remixicon-icon {
      transform: translateX(-4px);
    }
  }

  ${media.md`
    font-size: 1.125rem;
  `}
`

const Btn = styled.button`
  ${btnStyles}
`

const Anchor = styled.a`
  ${btnStyles}
`

export default function BackButton({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  return (
    <Btn {...props}>
      <ArrowLeftSLineIcon size={20} color={styledTheme.palette.lightBlue} />
      {children}
    </Btn>
  )
}

export const BackAnchor = React.forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>(function BackAnchor(
  {children, ...props}: React.AnchorHTMLAttributes<HTMLAnchorElement>,
  ref,
) {
  return (
    <Anchor {...props} ref={ref}>
      <ArrowLeftSLineIcon size={20} color={styledTheme.palette.lightBlue} />
      {children}
    </Anchor>
  )
})
