import {useCallback} from 'react'

import TagManager from 'react-gtm-module'

import {getListingPrice} from './price'
import {useEvents} from '../contexts'
import {onDutyFree} from '../constants/channels'
import {ProductDetail, Variant, VariantDetail, VariantList} from '../api/rest'

export const sendEcommerceNullEvent = () => {
  // Send ecommerce null event to google tagmanager
  TagManager.dataLayer({
    dataLayer: {
      ecommerce: null,
    },
    dataLayerName: 'dataLayer',
  })
}

export const sendDataLayerEvent = (
  event: string,
  category?: string,
  event_label?: string,
  value?: number,
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      category: category !== undefined ? category : undefined,
      event_label: event_label !== undefined ? event_label : undefined,
      value: value !== undefined ? value : undefined,
    },
    dataLayerName: 'dataLayer',
  })
}

export const sendDataLayerLoginEvent = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'login',
      method: 'rafraen skilriki',
      category: 'login',
    },
    dataLayerName: 'dataLayer',
  })
}

interface UserEvents {
  event?: 'logout' | 'user_switch_ssn'
  user_type: 'business' | 'person' | 'guest'
  user_logged_in: 'guest' | 'rafraen_skilriki'
  user_has_business?: boolean | 'n/a'
  user_business_has_credit?: boolean | 'n/a'
}

export const sendDataLayerUserEvent = ({
  event,
  user_type,
  user_logged_in,
  user_has_business = 'n/a',
  user_business_has_credit = 'n/a',
}: UserEvents) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      user_type,
      user_logged_in,
      user_has_business,
      user_business_has_credit,
    },
    dataLayerName: 'dataLayer',
  })
}

export const sendEcommerceItemListEvent = (
  products: VariantList[],
  list: string,
) => {
  sendEcommerceNullEvent()

  // GA4 - Replaces impression event
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_item_list',
      ecommerce: {
        item_list_name: list,
        items: products.map((variant, index) => ({
          item_name: variant.name,
          item_id: variant.sku,
          price: getListingPrice(variant)?.price,
          item_category: variant.product.type,
          elko_recommends: variant.recommended,
          index: index + 1,
        })),
      },
    },
    dataLayerName: 'dataLayer',
  })
}

export const sendEcommerceAddToWishlistEvent = (
  name: string,
  sku: string,
  price: number,
  type: string,
  recommended?: boolean,
) => {
  sendEcommerceNullEvent()

  TagManager.dataLayer({
    dataLayer: {
      event: 'add_to_wishlist',
      event_label: sku,
      ecommerce: {
        items: {
          item_name: name,
          item_id: sku,
          price: price,
          item_category: type,
          elko_recommends: recommended,
          quantity: 1,
        },
        currency: 'ISK',
        value: price,
      },
    },
    dataLayerName: 'dataLayer',
  })
}

export const sendEcommerceAddToCompareEvent = (
  variant: VariantDetail | VariantList,
) => {
  sendEcommerceNullEvent()

  TagManager.dataLayer({
    dataLayer: {
      event: 'add_to_compare',
      event_label: variant.sku,
      ecommerce: {
        items: {
          item_name: variant.name,
          item_id: variant.sku,
          price: getListingPrice(variant)?.price ?? undefined,
          item_category: variant.product?.type ?? undefined,
          elko_recommends: variant.recommended ?? undefined,
          quantity: 1,
        },
        currency: 'ISK',
        value: getListingPrice(variant)?.price ?? undefined,
      },
    },
    dataLayerName: 'dataLayer',
  })
}

export const sendEcommerceAddToCompareEventNew = (
  product: ProductDetail,
  variant: Variant | VariantList,
) => {
  sendEcommerceNullEvent()

  TagManager.dataLayer({
    dataLayer: {
      event: 'add_to_compare',
      event_label: variant.sku,
      ecommerce: {
        items: {
          item_name: variant.name,
          item_id: variant.sku,
          price: getListingPrice(variant)?.price ?? undefined,
          item_category: product?.type ?? undefined,
          elko_recommends: variant.recommended ?? undefined,
          quantity: 1,
        },
        currency: 'ISK',
        value: getListingPrice(variant)?.price ?? undefined,
      },
    },
    dataLayerName: 'dataLayer',
  })
}

export type SearchType =
  | 'search_history'
  | 'input_field'
  | 'item_suggestion'
  | 'category_suggestion'
  | 'query_suggestion'

interface SearchEvent {
  search_term: string
  search_type: SearchType
}

export default function useTagmanager() {
  const {listById, handleListNameState} = useEvents()

  const sendEcommerceClickEvent = useCallback(
    (variant: VariantList | VariantDetail, position: number, list: string) => {
      sendEcommerceNullEvent()

      handleListNameState(variant.sku || '', list)

      // GA4 - Select item event (replaces productClick)
      TagManager.dataLayer({
        dataLayer: {
          event: 'select_item',
          ecommerce: {
            item_list_name: list,
            items: [
              {
                item_id: variant.sku,
                item_name: variant.name,
                price: getListingPrice(variant)?.price,
                index: position,
                item_category: variant.product.type,
              },
            ],
          },
        },
        dataLayerName: 'dataLayer',
      })
    },
    [handleListNameState],
  )

  const sendEcommerceAddToCartEvent = useCallback(
    (
      variants: (VariantDetail | VariantList | Variant | undefined)[],
      listName?: string,
      product?: ProductDetail,
    ) => {
      sendEcommerceNullEvent()

      const eventLabel =
        variants?.length === 1 ? variants?.[0]?.sku : 'multi-add'

      // GA4 add to cart event
      const eventPrice = variants?.reduce((totalPrice: number, variant) => {
        return totalPrice + (getListingPrice(variant)?.price || 0)
      }, 0)

      TagManager.dataLayer({
        dataLayer: {
          event: 'add_to_cart',
          event_label: eventLabel,
          ecommerce: {
            items: variants?.map((variant) => {
              const productInfo =
                variant && 'product' in variant ? variant?.product : product
              return {
                item_name: variant?.name,
                item_id: variant?.sku,
                price: getListingPrice(variant)?.price || 0,
                item_category: productInfo?.type,
                elko_recommends: variant?.recommended,
                quantity: 1,
                item_list_name: listName || listById?.[variant?.sku || ''],
              }
            }),
            currency: 'ISK',
            value: eventPrice,
          },
        },
        dataLayerName: 'dataLayer',
      })
    },
    [listById],
  )

  const sendSearchClickEvent = useCallback(
    ({search_term, search_type}: SearchEvent) => {
      sendEcommerceNullEvent()

      TagManager.dataLayer({
        dataLayer: {
          event: 'search',
          search_term,
          search_type,
        },
        dataLayerName: 'dataLayer',
      })
    },
    [],
  )

  const sendEcommerceItemViewEvent = useCallback(
    (variant: VariantDetail | Variant, product?: ProductDetail) => {
      sendEcommerceNullEvent()
      const productInfo =
        variant && 'product' in variant ? variant?.product : product
      // GA4 view item event (replaces productDetailView)
      TagManager.dataLayer({
        dataLayer: {
          event: 'view_item',
          ecommerce: {
            currency: 'ISK',
            value: getListingPrice(variant)?.lowestPrice,
            items: [
              {
                item_id: variant.sku,
                item_name: productInfo?.name,
                price: getListingPrice(variant)?.lowestPrice,
                item_category: productInfo?.type,
                item_list_name: listById?.[variant?.sku || ''],
                stock_status_web: (
                  onDutyFree ? variant.isInStockDutyfree : variant.isInStockWeb
                )
                  ? 'in_stock'
                  : 'out_of_stock',
              },
            ],
          },
        },
        dataLayerName: 'dataLayer',
      })
    },
    [listById],
  )

  return {
    sendSearchClickEvent,
    sendEcommerceClickEvent,
    sendEcommerceItemViewEvent,
    sendEcommerceAddToCartEvent,
  }
}
