import styled from 'styled-components'
import {palette} from 'styled-tools'

import {fluidRange} from '../../utils/styles'

interface PriceTextProps {
  size?: string | ReturnType<typeof fluidRange>
  oldPriceSize?: string | ReturnType<typeof fluidRange>
  dutyfreeSize?: string
}

const applyPriceTextSize = (size: string | ReturnType<typeof fluidRange>) => {
  if (typeof size === 'string') {
    return `font-size: ${size};`
  }
  return size
}

const applyOldPriceTextSize = (
  oldPriceSize: string | ReturnType<typeof fluidRange>,
) => {
  if (typeof oldPriceSize === 'string') {
    return `font-size: ${oldPriceSize};`
  }
  return oldPriceSize
}

const PriceText = styled.span<PriceTextProps>`
  font-family: 'ElkjopHeadline', sans-serif;
  color: ${palette('blue')};
  text-align: right;
  text-transform: uppercase;
  white-space: nowrap;
  ${({size}) =>
    size ? applyPriceTextSize(size) : fluidRange('font-size', '24px', '28px')}

  &.small {
    line-height: 1.5;
    ${fluidRange('font-size', '16px', '24px')}
  }

  &.large {
    line-height: 1;
    ${fluidRange('font-size', '36px', '56px')}
  }

  &.grey {
    font-family: 'ElkjopSans', sans-serif;
    line-height: 1;
    color: ${palette('ui50Solid')};
    ${fluidRange('font-size', '14px', '16px')}
  }
`

export default PriceText

export const OldPriceText = styled.span<PriceTextProps>`
  position: relative;
  font-weight: 500;
  color: ${palette('blue')};
  line-height: 1.1;
  margin-right: 5px;
  padding-bottom: 2px;
  white-space: nowrap;
  z-index: 0;
  ${({oldPriceSize}) =>
    oldPriceSize
      ? applyOldPriceTextSize(oldPriceSize)
      : fluidRange('font-size', '16px', '18px')}

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 35%;
    left: -2px;
    right: -2px;
    height: 4px;
    background-color: ${palette('pink')};
    z-index: -1;
  }

  &.small {
    font-size: 16px;
  }

  &.large {
    font-size: 18px;
  }

  &.grey {
    line-height: 1.6;
    color: ${palette('ui50Solid')};
    ${fluidRange('font-size', '12px', '14px')}
  }
`

export const DutyfreeText = styled.span<PriceTextProps>`
  position: relative;
  font-weight: 700;
  color: ${palette('blue')};
  line-height: 1.1;
  margin-right: 5px;
  white-space: nowrap;
  z-index: 0;
  ${({dutyfreeSize}) =>
    dutyfreeSize
      ? `
          font-size: ${dutyfreeSize};
        `
      : fluidRange('font-size', '16px', '18px')}

  &.small {
    font-size: 16px;
  }

  &.large {
    font-size: 18px;
  }
`
