import getConfig from "next/config"

const clientId = getConfig().publicRuntimeConfig.cognitoClientId

export const deleteAllCognitoCookies = async () => {
  const cookies = document.cookie.split(';')

  cookies.forEach(cookie => {
      const [name] = cookie.trim().split('=')
      if (name.startsWith('CognitoIdentityServiceProvider')) {
          document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/'
      }
  })
}

export const deleteOldestCookies = (maxCookies: number) => {
  const cookies = document.cookie.split(';')
  if (cookies.length <= maxCookies) return;

  const cookieTimestamps = cookies
      .filter(cookie => cookie.trim().startsWith('timestamp_'))
      .map(cookie => {
          const [name, timestamp] = cookie.trim().split('=')
          return { name: name.substring(10), timestamp: parseInt(timestamp, 10) }
      })
      .sort((a, b) => a.timestamp - b.timestamp)

  while (cookieTimestamps.length > maxCookies) {
      const oldest = cookieTimestamps.shift()
      document.cookie = oldest?.name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/'
      document.cookie = 'timestamp_' + oldest?.name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/'
  }
}

export const deleteCookiesFromAnotherDomain = (domain: string) => {
  const cookies = document.cookie.split(';')
  cookies.forEach(cookie => {
      const [name] = cookie.trim().split('=')
      if (name.startsWith('CognitoIdentityServiceProvider')) {
          document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=' + domain
      }
  })
}

export const deleteCognitoCookiesWithAnotherClientId = () => {
  const cookies = document.cookie.split(';')

  cookies.forEach(cookie => {
      const [name] = cookie.trim().split('=')
      if (name.startsWith('CognitoIdentityServiceProvider.')) {
        if (!name.startsWith('CognitoIdentityServiceProvider.' + clientId)) {
          document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/'
        }
      }
  })
}
