import React, {useCallback, useState} from 'react'

import cn from 'classnames'
import styled from 'styled-components'
import {palette} from 'styled-tools'

const Tabs = styled.div`
  color: ${palette('ui50')};
  font-weight: 800;
  padding: 32px 0;
  margin-right: 80px;
  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }

  &.isActive {
    color: ${palette('blue')};
    border-bottom: 4px solid ${palette('blue')};
    cursor: inherit;
  }
`

const TabContainer = styled.div`
  display: flex;
`

const TabWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${palette('border')};
`

const TabContent = styled.div`
  display: none;
  padding-top: 20px;

  &.isActive {
    display: block;
  }
`

interface Props {
  tabs: string[]
  children: React.ReactNode[]
  onSelect?: (value: string) => void
}

export default function TabComponent({tabs, children, onSelect}: Props) {
  const [activeTab, setActiveTab] = useState<number>(0)

  const handleSelect = useCallback(
    (label: string, index: number) => {
      onSelect(label)
      setActiveTab(index)
    },
    [onSelect],
  )

  return (
    <>
      <TabContainer>
        <TabWrapper>
          {tabs.map((label, i) => (
            <Tabs
              key={i}
              className={cn({isActive: activeTab === i})}
              onClick={() => handleSelect(label, i)}
            >
              {label}
            </Tabs>
          ))}
        </TabWrapper>
      </TabContainer>

      {React.Children.map(children, (child, i) => (
        <TabContent key={i} className={cn({isActive: activeTab === i})}>
          {child}
        </TabContent>
      ))}
    </>
  )
}
