import {useEffect} from 'react'

import CustomerService2LineIcon from 'remixicon-react/CustomerService2LineIcon'

import {Button} from '@festi/common/components/buttons'
import {useBambuser} from '@festi/common/contexts'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BambuserOneToOneEmbed: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBambuserOneToOneReady: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE: any
  }
}

interface Props {
  label?: string
  disabled?: boolean
  onClick?: () => void
}

export default function VideoCall({
  label,
  disabled,
  onClick,
}: Props): JSX.Element {
  const {
    oneToOneEmbed,
    updateOneToOne,
    cartIntegration,
    provideProductData,
    handleLoadBambuser,
    handleOpenVideoCall,
  } = useBambuser()

  useEffect(() => {
    if (window.BambuserOneToOneEmbed && !oneToOneEmbed) {
      updateOneToOne()
    }

    window.onBambuserOneToOneReady = () => {
      updateOneToOne()
    }
  }, [oneToOneEmbed, updateOneToOne])

  useEffect(() => {
    handleLoadBambuser()

    if (oneToOneEmbed) {
      cartIntegration(oneToOneEmbed)
      provideProductData(oneToOneEmbed)
    }
  }, [oneToOneEmbed, cartIntegration, provideProductData, handleLoadBambuser])

  return (
    <Button
      icon={CustomerService2LineIcon}
      accentColor="green"
      onClick={() => {
        !!onClick && onClick()
        handleOpenVideoCall()
      }}
      disabled={disabled}
      withBar
    >
      {label || 'Myndsímtal'}
    </Button>
  )
}
