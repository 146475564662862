import styled, {css} from 'styled-components'
import {palette, ifProp} from 'styled-tools'

import {fluidRange} from '../../utils/styles'

export interface HeaderProps {
  center?: boolean
  headline?: boolean
  withGutter?: boolean
  darkMode?: boolean
  size?: 1 | 2 | 3 | 4 | 5 | 6
  auto?: boolean
  banner?: boolean
}

function fontSize(from: number, to: number) {
  return css`
    ${fluidRange('font-size', `${from * 16}px`, `${to * 16}px`)}
  `
}

const sizeMap = {
  1: fontSize(2.2, 2.8),
  2: fontSize(1.8, 2.2),
  3: fontSize(1.3, 1.8),
  4: fontSize(1.25, 1.5),
  5: fontSize(1, 1.125),
  6: fontSize(0.875, 1),
}

const bannerStyle = css`
  position: relative;
  width: fit-content;
  margin: 20px auto 30px;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: -15px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 30px);
    z-index: -1;
    background-color: ${palette('lightBlue')};
    transform: matrix(1, -0.05, 0.06, 1, 0, 0);
  }
`

const common = css<HeaderProps>`
  font-family: ${ifProp('headline', 'ElkjopHeadline', 'ElkjopSans')}, sans-serif;
  line-height: 1.2;
  font-weight: ${ifProp('headline', 400, 500)};
  width: ${ifProp({auto: true}, 'auto', '100%')};
  color: ${ifProp({darkMode: true}, palette('white'), palette('blue'))};
  text-align: ${ifProp('center', 'center', 'left')};
  word-break: break-word;

  u {
    text-decoration: inherit;
  }
  i {
    font-style: inherit;
  }
  b,
  strong {
    font-weight: inherit;
  }

  ${(p) => p.banner && bannerStyle}
`

const heading1 = css<HeaderProps>`
  ${(props) => (props.size ? sizeMap[props.size] : sizeMap[1])}
  margin-bottom: ${ifProp({withGutter: true}, '32px', '0')};
  ${common}
`

const heading2 = css<HeaderProps>`
  ${(props) => (props.size ? sizeMap[props.size] : sizeMap[2])}
  margin-bottom: ${ifProp({withGutter: true}, '24px', '0')};
  ${common}
`

const heading3 = css<HeaderProps>`
  ${(props) => (props.size ? sizeMap[props.size] : sizeMap[3])}
  margin-bottom: ${ifProp({withGutter: true}, '24px', '0')};
  ${common}
`

const heading4 = css<HeaderProps>`
  ${(props) => (props.size ? sizeMap[props.size] : sizeMap[4])}
  margin-bottom: ${ifProp({withGutter: true}, '16px', '0')};
  ${common}
`

const heading5 = css<HeaderProps>`
  ${(props) => (props.size ? sizeMap[props.size] : sizeMap[5])}
  margin-bottom: ${ifProp({withGutter: true}, '16px', '0')};
  ${common}
`

const heading6 = css<HeaderProps>`
  ${(props) => (props.size ? sizeMap[props.size] : sizeMap[6])}
  margin-bottom: ${ifProp({withGutter: true}, '8px', '0')};
  ${common}
`

export const H1 = styled.h1<HeaderProps>`
  ${heading1}
`

export const H2 = styled.h2<HeaderProps>`
  ${heading2}
`

export const H3 = styled.h3<HeaderProps>`
  ${heading3}
`

export const H4 = styled.h4<HeaderProps>`
  ${heading4}
`

export const H5 = styled.h5<HeaderProps>`
  ${heading5}
`

export const H6 = styled.h6<HeaderProps>`
  ${heading6}
`
