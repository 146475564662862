import React from 'react'

import ReactModal from 'react-modal'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'
import {CloseButton} from '../buttons'

interface Props extends ReactModal.Props {
  backgroundColor?: string
  children?: React.ReactNode
}

interface CustomModalStyleProps {
  content?: React.CSSProperties
  overlay?: React.CSSProperties
}

const StyledModal = styled(ReactModal)<Props>`
  background-color: ${(p) => palette(p?.backgroundColor || 'white')};
`

const customStyles: CustomModalStyleProps = {
  overlay: {
    zIndex: styledTheme.zIndex.modal,
  },
  content: {
    height: '100vh',
    WebkitOverflowScrolling: 'touch',
  },
}

const CloseButtonWrapper = styled.div`
  position: absolute;
  ${fluidRange('top', '8px', '20px')}
  ${fluidRange('right', '8px', '20px')}
`

export default function FullScreenModal({
  backgroundColor,
  children,
  onRequestClose,
  ...props
}: Props): JSX.Element {
  return (
    <StyledModal
      style={customStyles}
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden'
      }}
      onAfterClose={() => {
        document.body.style.overflow = 'unset'
      }}
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
      ariaHideApp={false}
      backgroundColor={backgroundColor}
      {...props}
    >
      {children}
      {!!onRequestClose && (
        <CloseButtonWrapper>
          <CloseButton onClick={onRequestClose} />
        </CloseButtonWrapper>
      )}
    </StyledModal>
  )
}
