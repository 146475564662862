export const POSTAL_CODES: Record<string, string> = {
  P101: '101 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P102: '102 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P103: '103 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P104: '104 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P105: '105 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P107: '107 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P108: '108 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P109: '109 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P110: '110 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P111: '111 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P112: '112 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P113: '113 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P116: '116 Höfuðborgarsvæðið - Reykjavík (Þéttbýli)',
  P121: '121 Höfuðborgarsvæðið - Reykjavík (Pósthólf)',
  P123: '123 Höfuðborgarsvæðið - Reykjavík (Pósthólf)',
  P124: '124 Höfuðborgarsvæðið - Reykjavík (Pósthólf)',
  P125: '125 Höfuðborgarsvæðið - Reykjavík (Pósthólf)',
  P127: '127 Höfuðborgarsvæðið - Reykjavík (Pósthólf)',
  P128: '128 Höfuðborgarsvæðið - Reykjavík (Pósthólf)',
  P129: '129 Höfuðborgarsvæðið - Reykjavík (Pósthólf)',
  P130: '130 Höfuðborgarsvæðið - Reykjavík (Pósthólf)',
  P132: '132 Höfuðborgarsvæðið - Reykjavík (Pósthólf)',
  P150: '150 Höfuðborgarsvæðið - Reykjavík (Opinberar stofnanir)',
  P155: '155 Höfuðborgarsvæðið - Reykjavík (Einkafyrirtæki)',
  P161: '161 Höfuðborgarsvæðið - Reykjavík (Dreifbýli)',
  P162: '162 Höfuðborgarsvæðið - Reykjavík - Dreifbýli (Dreifbýli)',
  P170: '170 Höfuðborgarsvæðið - Seltjarnarnesi (Þéttbýli)',
  P172: '172 Höfuðborgarsvæðið - Seltjarnarnesi (Pósthólf)',
  P190: '190 Suðurland og Reykjanes - Vogum (Þéttbýli)',
  P191: '191 Suðurland og Reykjanes - Vogum (Dreifbýli)',
  P200: '200 Höfuðborgarsvæðið - Kópavogi (Þéttbýli)',
  P201: '201 Höfuðborgarsvæðið - Kópavogi (Þéttbýli)',
  P202: '202 Höfuðborgarsvæðið - Kópavogi (Pósthólf)',
  P203: '203 Höfuðborgarsvæðið - Kópavogi (Þéttbýli)',
  P206: '206 Höfuðborgarsvæðið - Kópavogi (Dreifbýli)',
  P210: '210 Höfuðborgarsvæðið - Garðabæ (Þéttbýli)',
  P212: '212 Höfuðborgarsvæðið - Garðabæ (Pósthólf)',
  P220: '220 Höfuðborgarsvæðið - Hafnarfirði (Þéttbýli)',
  P221: '221 Höfuðborgarsvæðið - Hafnarfirði (Þéttbýli)',
  P222: '222 Höfuðborgarsvæðið - Hafnarfirði (Pósthólf)',
  P225: '225 Höfuðborgarsvæðið - Garðabæ (Þéttbýli)',
  P230: '230 Suðurland og Reykjanes - Reykjanesbæ (Þéttbýli)',
  P232: '232 Suðurland og Reykjanes - Reykjanesbæ (Pósthólf)',
  P233: '233 Suðurland og Reykjanes - Reykjanesbæ (Dreifbýli)',
  P235: '235 Suðurland og Reykjanes - Keflavíkurflugvelli (Þéttbýli)',
  P240: '240 Suðurland og Reykjanes - Grindavík (Þéttbýli)',
  P241: '241 Suðurland og Reykjanes - Grindavík (Dreifbýli)',
  P245: '245 Suðurland og Reykjanes - Suðurnesjabæ (Þéttbýli)',
  P246: '246 Suðurland og Reykjanes - Suðurnesjabæ (Dreifbýli)',
  P250: '250 Suðurland og Reykjanes - Suðurnesjabæ (Þéttbýli)',
  P251: '251 Suðurland og Reykjanes - Suðurnesjabæ (Dreifbýli)',
  P260: '260 Suðurland og Reykjanes - Reykjanesbæ (Þéttbýli)',
  P262: '262 Suðurland og Reykjanes - Reykjanesbæ (Þéttbýli)',
  P270: '270 Höfuðborgarsvæðið - Mosfellsbæ (Þéttbýli)',
  P271: '271 Höfuðborgarsvæðið - Mosfellsbæ (Dreifbýli)',
  P276: '276 Höfuðborgarsvæðið - Mosfellsbæ (Dreifbýli)',
  P300: '300 Vesturland og Vestfirðir - Akranesi (Þéttbýli)',
  P301: '301 Vesturland og Vestfirðir - Akranesi (Dreifbýli)',
  P302: '302 Vesturland og Vestfirðir - Akranesi (Pósthólf)',
  P310: '310 Vesturland og Vestfirðir - Borgarnesi (Þéttbýli)',
  P311: '311 Vesturland og Vestfirðir - Borgarnesi (Dreifbýli)',
  P320: '320 Vesturland og Vestfirðir - Reykholti í Borgarfirði (Dreifbýli)',
  P340: '340 Vesturland og Vestfirðir - Stykkishólmi (Þéttbýli)',
  P341: '341 Vesturland og Vestfirðir - Stykkishólmi (Dreifbýli)',
  P342: '342 Vesturland og Vestfirðir - Stykkishólmi (Dreifbýli)',
  P345: '345 Vesturland og Vestfirðir - Flatey á Breiðafirði (Dreifbýli)',
  P350: '350 Vesturland og Vestfirðir - Grundarfirði (Þéttbýli)',
  P351: '351 Vesturland og Vestfirðir - Grundarfirði (Dreifbýli)',
  P355: '355 Vesturland og Vestfirðir - Ólafsvík (Þéttbýli)',
  P356: '356 Vesturland og Vestfirðir - Snæfellsbæ (Dreifbýli)',
  P360: '360 Vesturland og Vestfirðir - Hellissandi (Þéttbýli)',
  P370: '370 Vesturland og Vestfirðir - Búðardal (Þéttbýli)',
  P371: '371 Vesturland og Vestfirðir - Búðardal (Dreifbýli)',
  P380: '380 Vesturland og Vestfirðir - Reykhólahreppi (Þéttbýli)',
  P381: '381 Vesturland og Vestfirðir - Reykhólahreppi (Dreifbýli)',
  P400: '400 Vesturland og Vestfirðir - Ísafirði (Þéttbýli)',
  P401: '401 Vesturland og Vestfirðir - Ísafirði (Dreifbýli)',
  P410: '410 Vesturland og Vestfirðir - Hnífsdal (Þéttbýli)',
  P415: '415 Vesturland og Vestfirðir - Bolungarvík (Þéttbýli)',
  P416: '416 Vesturland og Vestfirðir - Bolungarvík (Dreifbýli)',
  P420: '420 Vesturland og Vestfirðir - Súðavík (Þéttbýli)',
  P421: '421 Vesturland og Vestfirðir - Súðavík (Dreifbýli)',
  P425: '425 Vesturland og Vestfirðir - Flateyri (Þéttbýli)',
  P426: '426 Vesturland og Vestfirðir - Flateyri (Dreifbýli)',
  P430: '430 Vesturland og Vestfirðir - Suðureyri (Þéttbýli)',
  P431: '431 Vesturland og Vestfirðir - Suðureyri (Dreifbýli)',
  P450: '450 Vesturland og Vestfirðir - Patreksfirði (Þéttbýli)',
  P451: '451 Vesturland og Vestfirðir - Patreksfirði (Dreifbýli)',
  P460: '460 Vesturland og Vestfirðir - Tálknafirði (Þéttbýli)',
  P461: '461 Vesturland og Vestfirðir - Tálknafirði (Dreifbýli)',
  P465: '465 Vesturland og Vestfirðir - Bíldudal (Þéttbýli)',
  P466: '466 Vesturland og Vestfirðir - Bíldudal (Dreifbýli)',
  P470: '470 Vesturland og Vestfirðir - Þingeyri (Þéttbýli)',
  P471: '471 Vesturland og Vestfirðir - Þingeyri (Dreifbýli)',
  P500: '500 Vesturland og Vestfirðir - Stað (Dreifbýli)',
  P510: '510 Vesturland og Vestfirðir - Hólmavík (Þéttbýli)',
  P511: '511 Vesturland og Vestfirðir - Hólmavík (Dreifbýli)',
  P512: '512 Vesturland og Vestfirðir - Hólmavík (Dreifbýli)',
  P520: '520 Vesturland og Vestfirðir - Drangsnesi (Þéttbýli)',
  P524: '524 Vesturland og Vestfirðir - Árneshreppi (Dreifbýli)',
  P530: '530 Norðurland - Hvammstanga (Þéttbýli)',
  P531: '531 Norðurland - Hvammstanga (Dreifbýli)',
  P540: '540 Norðurland - Blönduósi (Þéttbýli)',
  P541: '541 Norðurland - Blönduósi (Dreifbýli)',
  P545: '545 Norðurland - Skagaströnd (Þéttbýli)',
  P546: '546 Norðurland - Skagaströnd (Dreifbýli)',
  P550: '550 Norðurland - Sauðárkróki (Þéttbýli)',
  P551: '551 Norðurland - Sauðárkróki (Dreifbýli)',
  P560: '560 Norðurland - Varmahlíð (Þéttbýli)',
  P561: '561 Norðurland - Varmahlíð (Dreifbýli)',
  P565: '565 Norðurland - Hofsósi (Þéttbýli)',
  P566: '566 Norðurland - Hofsósi (Dreifbýli)',
  P570: '570 Norðurland - Fljótum (Dreifbýli)',
  P580: '580 Norðurland - Siglufirði (Þéttbýli)',
  P581: '581 Norðurland - Siglufirði (Dreifbýli)',
  P600: '600 Norðurland - Akureyri (Þéttbýli)',
  P601: '601 Norðurland - Akureyri (Dreifbýli)',
  P602: '602 Norðurland - Akureyri (Pósthólf)',
  P603: '603 Norðurland - Akureyri (Þéttbýli)',
  P604: '604 Norðurland - Akureyri (Dreifbýli)',
  P605: '605 Norðurland - Akureyri (Dreifbýli)',
  P606: '606 Norðurland - Akureyri (Dreifbýli)',
  P607: '607 Norðurland - Akureyri (Dreifbýli)',
  P610: '610 Norðurland - Grenivík (Þéttbýli)',
  P611: '611 Norðurland - Grímsey (Þéttbýli)',
  P616: '616 Norðurland - Grenivík (Dreifbýli)',
  P620: '620 Norðurland - Dalvík (Þéttbýli)',
  P621: '621 Norðurland - Dalvík (Dreifbýli)',
  P625: '625 Norðurland - Ólafsfirði (Þéttbýli)',
  P626: '626 Norðurland - Ólafsfirði (Dreifbýli)',
  P630: '630 Norðurland - Hrísey (Þéttbýli)',
  P640: '640 Norðurland - Húsavík (Þéttbýli)',
  P641: '641 Norðurland - Húsavík (Dreifbýli)',
  P645: '645 Norðurland - Fosshóli (Dreifbýli)',
  P650: '650 Norðurland - Laugum (Þéttbýli)',
  P660: '660 Norðurland - Mývatni (Dreifbýli)',
  P670: '670 Norðurland - Kópaskeri (Þéttbýli)',
  P671: '671 Norðurland - Kópaskeri (Dreifbýli)',
  P675: '675 Norðurland - Raufarhöfn (Þéttbýli)',
  P676: '676 Norðurland - Raufarhöfn (Dreifbýli)',
  P680: '680 Norðurland - Þórshöfn (Þéttbýli)',
  P681: '681 Norðurland - Þórshöfn (Dreifbýli)',
  P685: '685 Norðurland - Bakkafirði (Þéttbýli)',
  P686: '686 Norðurland - Bakkafirði (Dreifbýli)',
  P690: '690 Norðurland - Vopnafirði (Þéttbýli)',
  P691: '691 Norðurland - Vopnafirði (Dreifbýli)',
  P700: '700 Austurland - Egilsstöðum (Þéttbýli)',
  P701: '701 Austurland - Egilsstöðum (Dreifbýli)',
  P710: '710 Austurland - Seyðisfirði (Þéttbýli)',
  P711: '711 Austurland - Seyðisfirði (Dreifbýli)',
  P715: '715 Austurland - Mjóafirði (Dreifbýli)',
  P720: '720 Austurland - Borgarfirði (eystri) (Dreifbýli)',
  P721: '721 Austurland - Borgarfirði (eystri) (Dreifbýli)',
  P730: '730 Austurland - Reyðarfirði (Þéttbýli)',
  P731: '731 Austurland - Reyðarfirði (Dreifbýli)',
  P735: '735 Austurland - Eskifirði (Þéttbýli)',
  P736: '736 Austurland - Eskifirði (Dreifbýli)',
  P740: '740 Austurland - Neskaupstað (Þéttbýli)',
  P741: '741 Austurland - Neskaupstað (Dreifbýli)',
  P750: '750 Austurland - Fáskrúðsfirði (Þéttbýli)',
  P751: '751 Austurland - Fáskrúðsfirði (Dreifbýli)',
  P755: '755 Austurland - Stöðvarfirði (Þéttbýli)',
  P756: '756 Austurland - Stöðvarfirði (Dreifbýli)',
  P760: '760 Austurland - Breiðdalsvík (Þéttbýli)',
  P761: '761 Austurland - Breiðdalsvík (Dreifbýli)',
  P765: '765 Austurland - Djúpavogi (Þéttbýli)',
  P766: '766 Austurland - Djúpavogi (Dreifbýli)',
  P780: '780 Austurland - Höfn í Hornafirði (Þéttbýli)',
  P781: '781 Austurland - Höfn í Hornafirði (Dreifbýli)',
  P785: '785 Austurland - Öræfum (Dreifbýli)',
  P800: '800 Suðurland og Reykjanes - Selfossi (Þéttbýli)',
  P801: '801 Suðurland og Reykjanes - Selfossi (Dreifbýli)',
  P802: '802 Suðurland og Reykjanes - Selfossi (Pósthólf)',
  P803: '803 Suðurland og Reykjanes - Selfossi (Dreifbýli)',
  P804: '804 Suðurland og Reykjanes - Selfossi (Dreifbýli)',
  P805: '805 Suðurland og Reykjanes - Selfossi (Dreifbýli)',
  P806: '806 Suðurland og Reykjanes - Selfossi (Dreifbýli)',
  P810: '810 Suðurland og Reykjanes - Hveragerði (Þéttbýli)',
  P815: '815 Suðurland og Reykjanes - Þorlákshöfn (Þéttbýli)',
  P816: '816 Suðurland og Reykjanes - Ölfus (Dreifbýli)',
  P820: '820 Suðurland og Reykjanes - Eyrarbakka (Þéttbýli)',
  P825: '825 Suðurland og Reykjanes - Stokkseyri (Þéttbýli)',
  P840: '840 Suðurland og Reykjanes - Laugarvatni (Þéttbýli)',
  P845: '845 Suðurland og Reykjanes - Flúðum (Þéttbýli)',
  P846: '846 Suðurland og Reykjanes - Flúðum (Dreifbýli)',
  P850: '850 Suðurland og Reykjanes - Hellu (Þéttbýli)',
  P851: '851 Suðurland og Reykjanes - Hellu (Dreifbýli)',
  P860: '860 Suðurland og Reykjanes - Hvolsvelli (Þéttbýli)',
  P861: '861 Suðurland og Reykjanes - Hvolsvelli (Dreifbýli)',
  P870: '870 Suðurland og Reykjanes - Vík (Þéttbýli)',
  P871: '871 Suðurland og Reykjanes - Vík (Dreifbýli)',
  P880: '880 Suðurland og Reykjanes - Kirkjubæjarklaustri (Þéttbýli)',
  P881: '881 Suðurland og Reykjanes - Kirkjubæjarklaustri (Dreifbýli)',
  P900: '900 Suðurland og Reykjanes - Vestmannaeyjum (Þéttbýli)',
  P902: '902 Suðurland og Reykjanes - Vestmannaeyjum (Pósthólf)',
}
