import {add, sub, getDay, format, eachDayOfInterval} from 'date-fns'
import isToday from 'date-fns/isToday'
import is from 'date-fns/locale/is'
import fromPairs from 'lodash/fromPairs'

import {
  formatTime,
  parsePhoneNumber,
  cleanSSN,
  formatSSN,
} from '@festi/utils/strings'

import {
  Address,
  Checkout,
  DeliverySlot,
  VariantList,
  VariantDetail,
  CreateAddressRequest,
  Variant,
} from '../api/rest'
import {AddressInput, CountryCode} from '../api/saleor/types/global'
import {
  AddressFormValues,
  BillingAddressFormValues,
  DutyFreeAddressFormValues,
} from '../components/checkout/AddressFormFields'
import {POSTAL_CODES} from './postalCodes'
import {getListingPrice} from './price'
import {ensureList} from './typing'

export const EMPTY_SSN = '1111111119'

export function validKennitala(ssn: string): string {
  return ssn === EMPTY_SSN ? '' : ssn || ''
}

export function formatKennitala(ssn: string): string {
  const parsedKennitala = validKennitala(ssn)
  return !parsedKennitala ? '' : formatSSN(validKennitala(ssn))
}

type Tuple = [string, number]

export function getCartQuantityMap(cart?: Checkout, variant_ids?: string[]) {
  let pairs = ensureList(cart?.lines).map<Tuple>((line) => [
    `${line.variant.id}`,
    line.quantity,
  ])

  if (variant_ids != null) {
    pairs = pairs.filter(([id]) => variant_ids.includes(id))
  }

  return fromPairs(pairs)
}

// TODO: Test
export function parsePostalCode(code: string): string {
  return `P${crockfordBase32Decode(code)}`
}

export function getPostalCodeString(postalCode?: string) {
  if (!postalCode) return null
  return POSTAL_CODES[parsePostalCode(postalCode)]
}

function crockfordBase32Decode(b32: string): number {
  if (b32.length === 0) return 0
  const alphabet = '0123456789ABCDEFGHJKMNPQRSTVWXYZ'
  return (
    alphabet.indexOf(b32.slice(-1)) +
    crockfordBase32Decode(b32.slice(0, -1)) * 32
  )
}

export function isSameAddress(savedAddress?: Address, address?: Address) {
  return (
    savedAddress?.ssn === address?.ssn &&
    savedAddress?.streetAddress1 === address?.streetAddress1 &&
    savedAddress?.name === address?.name
  )
}

// TODO: Test
export function toAddressInput(address: Address): AddressInput {
  return {
    firstName: address.name,
    lastName: cleanSSN(address.ssn),
    companyName: address.companyName,
    streetAddress1: address.streetAddress1,
    streetAddress2: address.streetAddress2,
    city: null,
    cityArea: null,
    postalCode: null,
    country: address.postalCode.code as CountryCode,
    countryArea: null,
    phone: parsePhoneNumber(address.phone.toString()),
  }
}

// TODO: Test
export function addressValuesToAddressRequest(
  values: AddressFormValues,
): CreateAddressRequest {
  return {
    ssn: cleanSSN(values.ssn),
    name: values.name,
    companyName: values.company,
    streetAddress1: values.address,
    streetAddress2: values.apartment,
    postalCode: values.country.code,
    phone: parsePhoneNumber(values.phoneNumber.toString()),
  }
}

// TODO: Test
export function dutyFreeValuesToAddressInput(
  values: DutyFreeAddressFormValues,
): AddressInput {
  return {
    firstName: values.name,
    lastName: cleanSSN(values.ssn),
    phone: parsePhoneNumber(values.phoneNumber.toString()),
  }
}

// TODO: Test
export function addressValuesToAddressInput(
  values: AddressFormValues,
): AddressInput {
  return {
    firstName: values.name,
    lastName: cleanSSN(values.ssn),
    companyName: values.company,
    streetAddress1: values.address,
    streetAddress2: values.apartment,
    city: null,
    cityArea: null,
    postalCode: null,
    country: values.country.code as CountryCode,
    countryArea: null,
    phone: parsePhoneNumber(values.phoneNumber.toString()),
  }
}

// TODO: Test
export function billingValuesToAddressInput(
  values: BillingAddressFormValues,
): AddressInput {
  return {
    firstName: values.billingName,
    lastName: cleanSSN(values.billingSsn),
    companyName: values.billingCompany,
    streetAddress1: values.billingAddress,
    streetAddress2: values.billingApartment,
    city: null,
    cityArea: null,
    postalCode: null,
    country: values.billingCountry.code as CountryCode,
    countryArea: null,
    phone: parsePhoneNumber(values.billingPhoneNumber.toString()),
  }
}

function getDayLabel(day: Date): string {
  const monthLabel = format(day, 'do MMM', {locale: is})
  let weekdayLabel: string

  if (isToday(day)) {
    weekdayLabel = 'Í dag'
  } else if (isToday(sub(day, {days: 1}))) {
    weekdayLabel = 'Á morgun'
  } else {
    weekdayLabel = format(day, 'EEE', {locale: is})
  }

  return `${weekdayLabel} ${monthLabel}`
}

export type TimeSlot = {
  id: string
  name: string
  date: string
  slot: DeliverySlot
}

// TODO: Test
export function getAvailableSlots(slots: DeliverySlot[] = []) {
  let availableSlots: TimeSlot[] = []

  const today = new Date()

  const datesToCheck = eachDayOfInterval({
    start: today,
    end: add(today, {days: 14}),
  })

  datesToCheck.forEach((date: Date) => {
    const formattedDate = format(date, 'yyyy-MM-dd')

    const dayIndex = getDay(date)
    const weekday = dayIndex === 0 ? 6 : dayIndex - 1

    const slotsOfDay = slots.filter((slot) => slot?.weekdays?.includes(weekday))

    slotsOfDay.forEach((slot) => {
      // If we dont have specific availability for date, we show it as available
      if (!(slot.availability[formattedDate] < 1)) {
        availableSlots = [
          ...availableSlots,
          {
            id: slot.id.toString() + '-' + formattedDate,
            name: `${getDayLabel(date)}, kl. ${formatTime(
              slot.timeStart,
            )} - ${formatTime(slot.timeStop)}`,
            date: formattedDate,
            slot,
          },
        ]
      }
    })
  })

  return availableSlots
}

// TODO: Test
export function getDiscount(
  productVariant: VariantList | VariantDetail | Variant,
) {
  const discountedPrice = getListingPrice(productVariant)?.discountedPrice || 0
  const price = getListingPrice(productVariant)?.price || 0

  const discount = discountedPrice ? (price - discountedPrice) / price : null

  return discount
}

export function isScreenInsurance(sku: string) {
  return !!sku?.startsWith('AON5')
}

interface Insurance {
  sku: string
  months: number
}

export function getInsuranceText(insurance: Insurance): string {
  return isScreenInsurance(insurance?.sku)
    ? `${insurance.months} mán. skjátrygging`
    : `${insurance.months} mán. viðbótartrygging`
}
