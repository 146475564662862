import React from 'react'

import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon'
import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'

import {styledTheme} from '../../themes'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fadeColor?: string
  less?: boolean
}

const Btn = styled.button<Props>`
  position: relative;
  display: flex;
  flex-direction: ${ifProp({less: true}, 'column-reverse', 'column')};
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  border: 0;
  padding: 0 8px;
  cursor: pointer;
  height: 54px;

  &:before {
    content: '';
    display: ${ifProp({less: true}, 'none', 'block')};
    position: absolute;
    top: -80px;
    bottom: 100%;
    left: 0;
    right: 0;
    background: -moz-linear-gradient(
      top,
      rgba(229, 229, 229, 0) 0%,
      ${(p) => palette(p.fadeColor || 'white')} 99%,
      ${(p) => palette(p.fadeColor || 'white')} 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(229, 229, 229, 0) 0%,
      ${(p) => palette(p.fadeColor || 'white')} 99%,
      ${(p) => palette(p.fadeColor || 'white')} 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(229, 229, 229, 0) 0%,
      ${(p) => palette(p.fadeColor || 'white')} 99%,
      ${(p) => palette(p.fadeColor || 'white')} 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e5e5e5', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
  }

  .remixicon-icon {
    transform: rotate(${ifProp({less: true}, '180deg', '0')});
    transition: transform 0.15s ease-out;
  }

  &:focus,
  &:hover {
    outline: none;

    .remixicon-icon {
      transform: translateY(${ifProp({less: true}, '-4px', '4px')})
        rotate(${ifProp({less: true}, '180deg', '0')});
    }
  }
`

const BtnText = styled.span`
  color: ${palette('blue')};
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin: 2px 0;
`

export default function MoreButton({children, ...props}: Props): JSX.Element {
  return (
    <Btn {...props}>
      <BtnText>{children}</BtnText>
      <ArrowDownSLineIcon size={16} color={styledTheme.palette.lightBlue} />
    </Btn>
  )
}
