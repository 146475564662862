import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const ShowMobile = styled.div`
  display: block;

  ${media.md`
    display: none;
  `}
`

export const ShowDesktop = styled.div`
  display: none;

  ${media.md`
    display: block;
  `}
`
