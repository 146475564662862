import {Auth} from '@aws-amplify/auth'
import {HttpResponse} from './http-client'

export async function handleRestResponse<D, E>(
  promise: Promise<HttpResponse<D, E>>,
  undefined_on_404 = false,
): Promise<D> {
  const res = await promise.catch((e) => ({data: null, error: e}))

  if (res.error) {
    console.log('err', res.error)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (undefined_on_404 && (res.error as any).status === 404) {
      return undefined
    }
    if (res.error.status === 401 && typeof window !== 'undefined') {
      if (res.error.error?.detail === 'Token is expired') {
        // Token is expired
        // Sign out user
        console.error(JSON.stringify(res.error))
        Auth.signOut()
      } else {
        // Some other auth error
        console.error(JSON.stringify(res.error))
      }
    }
    throw res.error
  }

  return res.data
}

interface CacheResponse<D, E = unknown> {
  data: D
  error?: E
}

export async function handleCacheResponse<D, E>(
  promise: Promise<CacheResponse<D, E>>,
): Promise<D> {
  const res = await promise.catch((e) => ({data: null, error: e}))

  if (res.error) {
    throw res.error
  }

  return res.data
}
