import React from 'react'

import {media, Row, Col, ColProps} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette, switchProp} from 'styled-tools'

export enum TableItemAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface TableItem {
  item: React.ReactNode
  colSize: number
  align?: TableItemAlignment
}

interface Props {
  children: React.ReactNode
}

const Bar = styled.div`
  display: none;
  background-color: ${palette('blue')};
  margin-bottom: 16px;
  border: 1px solid ${palette('border')};
  padding: 12px 24px;

  ${media.sm`
    display: block;
  `}
`

interface InnerProps {
  align?: TableItemAlignment
}

export const TableInner = styled.div<InnerProps>`
  display: flex;
  align-items: center;
  justify-content: ${switchProp('align', {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  })};
  width: 100%;
  height: 100%;
  color: ${palette('white')};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
`

interface ItemProps extends InnerProps {
  children?: React.ReactNode
}

export function TableItem({
  align,
  children,
  ...props
}: ItemProps & ColProps): JSX.Element {
  return (
    <Col {...props}>
      <TableInner align={align}>{children}</TableInner>
    </Col>
  )
}

export default function TableHead({children}: Props): JSX.Element {
  return (
    <Bar>
      <Row justifyContent="between">{children}</Row>
    </Bar>
  )
}
