import React from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'

import {outlinedButtonStyles, buttonTextStyles} from './buttonStyles'

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  icon?: RemixiconReactIconComponentType
  borderColor?: 'blue' | 'ui20Solid'
}

interface TextProps {
  withIcon?: boolean
}

const Anchor = styled.a<Props>`
  ${(p) => outlinedButtonStyles({borderColor: p.borderColor})}
`

const BtnText = styled.span<TextProps>`
  ${buttonTextStyles}
`

const OutlinedAnchorButton = React.forwardRef<HTMLAnchorElement, Props>(
  function OutlinedAnchorButton({children, icon: Icon, ...props}: Props, ref) {
    return (
      <Anchor {...props} ref={ref}>
        <BtnText withIcon={!!Icon}>{children}</BtnText>
        {!!Icon && <Icon />}
      </Anchor>
    )
  },
)

export default OutlinedAnchorButton
