import styled from 'styled-components'
import {palette} from 'styled-tools'

const CardSubtitle = styled.span`
  font-family: 'ElkjopSans', sans-serif;
  line-height: 1.2;
  font-weight: 700;
  color: ${palette('ui30')};
  text-align: left;
  font-size: 0.875rem;
  text-transform: uppercase;

  -webkit-user-select: all;
  user-select: all;
`

export default CardSubtitle
