import styled from 'styled-components'
import {ifProp, palette} from 'styled-tools'

interface Props {
  isError?: boolean
  center?: boolean
}

const StatusMessage = styled.span<Props>`
  font-size: 1rem;
  font-weight: 400;
  color: ${ifProp({isError: true}, palette('error'), palette('success'))};
  line-height: 1.3;
  padding: 8px 0;
  text-align: ${ifProp({center: true}, 'center', 'left')};
`

export default StatusMessage
