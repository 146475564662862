import styled, {css} from 'styled-components'
import {palette, ifProp} from 'styled-tools'

import {fluidRange} from '../../utils/styles'

export interface ParagraphProps {
  center?: boolean
  withGutter?: boolean
  darkMode?: boolean
  bold?: boolean
}

function fontSize(from: number, to: number) {
  return css`
    ${fluidRange('font-size', `${from * 16}px`, `${to * 16}px`)}
  `
}

export const paragraphStyles = css<ParagraphProps>`
  font-family: 'ElkjopSans', sans-serif;
  line-height: 1.5;
  font-weight: ${ifProp({bold: true}, 500, 400)};
  width: 100%;
  color: ${ifProp({darkMode: true}, palette('white'), palette('blue'))};
  text-align: ${ifProp('center', 'center', 'left')};
  word-break: break-word;
`

export const P = styled.p<ParagraphProps>`
  ${fontSize(1, 1)}
  margin-bottom: ${ifProp({withGutter: true}, '16px', '0')};
  ${paragraphStyles}
`

export const PSmall = styled.p<ParagraphProps>`
  ${fontSize(0.875, 0.875)}
  margin-bottom: ${ifProp({withGutter: true}, '16px', '0')};
  ${paragraphStyles}
`

export const PMedium = styled.p<ParagraphProps>`
  ${fontSize(1.125, 1.125)}
  margin-bottom: ${ifProp({withGutter: true}, '16px', '0')};
  ${paragraphStyles}
`

export const PLarge = styled.p<ParagraphProps>`
  ${fontSize(1.375, 1.375)}
  margin-bottom: ${ifProp({withGutter: true}, '24px', '0')};
  ${paragraphStyles}
`

export const PXLarge = styled.p<ParagraphProps>`
  ${fontSize(1.5, 2)}
  margin-bottom: ${ifProp({withGutter: true}, '32px', '0')};
  ${paragraphStyles}
`
