import React, {useEffect} from 'react'
import {ApolloProvider} from '@apollo/client'
import 'cleave.js/dist/addons/cleave-phone.is'
import 'modern-normalize'
import {AppProps} from 'next/app'
import getConfig from 'next/config'
import Router from 'next/router'
import NProgress from 'nprogress'
import {CookiesProvider} from 'react-cookie'
import TagManager from 'react-gtm-module'
import {ToastContainer, Slide} from 'react-toastify'
import {GridThemeProvider} from 'styled-bootstrap-grid'
import {ThemeProvider} from 'styled-components'

import {useApollo} from '@festi/common/api/saleor'
import {
  CheckoutProvider,
  UserProvider,
  ComparisonProvider,
  EventProvider,
  BambuserProvider,
} from '@festi/common/contexts'
import {ResetStyle, GlobalStyle} from '@festi/common/styles'
import '@festi/common/styles/fontStyles.css'
import {styledTheme, gridTheme} from '@festi/common/themes'
import 'regenerator-runtime/runtime'
import 'nprogress/nprogress.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import {Amplify} from '@aws-amplify/core'
import {config} from '@festi/common/utils/cognitoConfig'

// This is for the progress bar, rendered on page loading
NProgress.configure({showSpinner: false})
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

Amplify.configure(config)

export default function App({Component, pageProps}: AppProps): JSX.Element {
  const saleorApolloClient = useApollo(pageProps.initialApolloState)

  useEffect(() => {
    TagManager.initialize({
      gtmId: getConfig().publicRuntimeConfig.gtmId,
      dataLayerName: 'dataLayer',
    })
  })

  useEffect(() => {
    const cookies = document.cookie.split('; ')
    // find cookies that start with "CognitoIdentityServiceProvider"
    const cognitoCookies = cookies.filter((cookie) =>
      cookie.startsWith('CognitoIdentityServiceProvider'),
    )

    // Compare all cognitoCookies to the applicationId we have and remove those that dont match
    cognitoCookies.forEach((cookie) => {
      const cookieClientId = cookie.split('.')[1]
      const currentClientId = getConfig().publicRuntimeConfig.cognitoClientId
      if (cookieClientId !== currentClientId) {
        const d = window.location.hostname.split('.')
        while (d.length > 3) {
          d.shift()
        }
        const cookieBase =
          encodeURIComponent(cookie.split(';')[0].split('=')[0]) +
          '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
          d.join('.') +
          ' ;path='
        const p = location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }
      }
    })
  }, [])

  return (
    <ApolloProvider client={saleorApolloClient}>
      <CookiesProvider>
        <UserProvider>
          <CheckoutProvider>
            <EventProvider>
              <ComparisonProvider>
                <ThemeProvider theme={styledTheme}>
                  <GridThemeProvider gridTheme={gridTheme}>
                    <BambuserProvider>
                      <>
                        <ToastContainer
                          position="top-right"
                          transition={Slide}
                        />
                        <ResetStyle />
                        <GlobalStyle />
                        <Component {...pageProps} />
                      </>
                    </BambuserProvider>
                  </GridThemeProvider>
                </ThemeProvider>
              </ComparisonProvider>
            </EventProvider>
          </CheckoutProvider>
        </UserProvider>
      </CookiesProvider>
    </ApolloProvider>
  )
}
