import React from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'

import {outlinedButtonStyles, buttonTextStyles} from './buttonStyles'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: RemixiconReactIconComponentType
  borderColor?: 'blue' | 'ui20Solid' | 'pink' | 'ui30Solid'
}
interface TextProps {
  withIcon?: boolean
}

const Btn = styled.button<Props>`
  ${(p) => outlinedButtonStyles({borderColor: p.borderColor})}
`

const BtnText = styled.span<TextProps>`
  ${buttonTextStyles}
`

export default function OutlinedButton({
  children,
  icon: Icon,
  ...props
}: Props): JSX.Element {
  return (
    <Btn {...props}>
      <BtnText withIcon={!!Icon}>{children}</BtnText>
      {!!Icon && <Icon />}
    </Btn>
  )
}
