import {fromUnixTime} from 'date-fns'

import {FieldType, ProductDetail, Variant, Warehouse} from '../api/rest'
import {onDutyFree} from '../constants/channels'

export interface Inventory {
  status: string
  warehouses: string[]
}

export interface Property {
  key: string
  value: string
  info: string
  isProperty: boolean
}

export enum ProductTagVariant {
  Discount = 'discount',
  New = 'new',
  Outlet = 'outlet',
  isRecommended = 'navEmm',
}

export type InventoryStock = 'sold_out' | 'in_stock' | 'low' | undefined

const isOutOfStockAtWarehouse = (inventoryStock: InventoryStock) => {
  return !inventoryStock || inventoryStock === 'sold_out'
}

export const hasFewInStock = (
  inventoryStatus: Record<string, InventoryStock>,
) => {
  if (!inventoryStatus) {
    return false
  }
  const stockLeft = Object.values(inventoryStatus).filter(
    (status: InventoryStock) => !isOutOfStockAtWarehouse(status),
  )

  return onDutyFree
    ? inventoryStatus['duty-free'] === 'low'
    : !stockLeft.find((status) => status !== 'low')
}

export const isOutOfStockWeb = (
  inventoryStatus: Record<string, InventoryStock>,
  isOnDutyfree: boolean,
): boolean => {
  if (!inventoryStatus) {
    return true
  }
  return isOnDutyfree
    ? isOutOfStockAtWarehouse(inventoryStatus['duty-free'])
    : isOutOfStockAtWarehouse(inventoryStatus['web'])
}

export const isOutOfStock = (
  inventoryStatus: Record<string, InventoryStock>,
) => {
  if (!inventoryStatus) {
    return true
  }
  const stockLeft = Object.values(inventoryStatus).filter(
    (status: InventoryStock) => !isOutOfStockAtWarehouse(status),
  )
  return !stockLeft?.length
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNewProduct = (online: number): boolean => {
  if (!online) {
    return false
  }

  const date = fromUnixTime(online)
  const diff = Date.now() - date.getTime()

  // if younger than 2 weeks then it's new
  return diff < 12096e5
}

type ProductVariant = {
  product: {
    attributes: Record<string, string>
  }
  attributes: Record<string, string>
}

export const formatProperties = (
  fieldTypes: FieldType[],
  productVariant: ProductVariant,
): Property[] | null => {
  if (!productVariant.attributes) {
    return null
  }

  // Get list of keys from attributes find in fieldLabel
  const keys = Object.keys(productVariant.attributes).concat(
    Object.keys(productVariant.product.attributes),
  )
  // Loop through fieldTypes and find the matching key from our product attributes
  return fieldTypes
    .flatMap((f) =>
      keys.includes(f.key)
        ? {
            key: f.label,
            value: `${
              productVariant.product.attributes[f.key] ||
              productVariant.attributes[f.key]
            }`,
            info: f.info,
            isProperty: f.isProperty,
            index: f.index,
          }
        : [],
    )
    .sort((a, b) => {
      return a.index - b.index
    })
}

// TODO: test type changes
export const formatPropertiesNEW = (
  fieldTypes: FieldType[],
  product: ProductDetail,
  variant: Variant,
): Property[] | null => {
  if (!variant.attributes) {
    return null
  }

  // Get list of keys from attributes find in fieldLabel
  const keys = Object.keys(variant.attributes).concat(
    Object.keys(product.attributes),
  )
  // Loop through fieldTypes and find the matching key from our product attributes
  return fieldTypes
    .flatMap((f) =>
      keys.includes(f.key)
        ? {
            key: f.label,
            value: `${product.attributes[f.key] || variant.attributes[f.key]}`,
            info: f.info,
            isProperty: f.isProperty,
            index: f.index,
          }
        : [],
    )
    .sort((a, b) => {
      return a.index - b.index
    })
}

// TODO: test
export function formatInventory(
  inventoryStatus: Record<string, InventoryStock>,
  warehouses: Warehouse[],
  collectAtWarehouse: boolean,
): Inventory[] {
  // Getting unique stockValues
  const stockValues = [...new Set(Object.values(inventoryStatus).sort())]

  // Going through each stock value and comparing to inventorystatus of warehouse
  return stockValues.flatMap((stockValue) => {
    const usableWarehouses = warehouses.flatMap((w) => {
      // If the stock value is the same as the inventory status of the warehouse
      const inventoryStock = inventoryStatus[w.slug]
      if (stockValue === inventoryStock && w.isStore === !collectAtWarehouse) {
        return w.name
      }
      return []
    })
    return stockValue && usableWarehouses.length > 0
      ? {
          status: stockValue,
          warehouses: usableWarehouses,
        }
      : []
  })
}
