/**
 * Always returns a list with `null` and `undefined` values removed.
 *
 * ```typescript
 * ensureList() // => []
 * ensureList(null) // => []
 * ensureList([null, undefined, true, false, '', 0]) // => [true, false, '', 0]
 * ```
 */
export function ensureList<T>(list?: Array<T> | null) {
  return list ? list.filter((x) => x != null) : []
}
