import {gql} from '@apollo/client'

export const countryFragment = gql`
  fragment CountryDisplayFields on CountryDisplay {
    code
    country
  }
`

export const addressFragment = gql`
  ${countryFragment}
  fragment AddressFields on Address {
    id
    firstName
    lastName
    companyName
    streetAddress1
    streetAddress2
    city
    cityArea
    postalCode
    country {
      ...CountryDisplayFields
    }
    countryArea
    phone
    isDefaultShippingAddress
    isDefaultBillingAddress
  }
`

export const checkoutFragment = gql`
  fragment CheckoutFields on Checkout {
    id
    token
  }
`

export const checkoutOrderFragment = gql`
  ${addressFragment}
  fragment CheckoutOrderFields on Order {
    id
    token
    shippingAddress {
      ...AddressFields
    }
    billingAddress {
      ...AddressFields
    }
    lines {
      id
      variant {
        id
        name
        sku
        product {
          id
          name
          slug
          thumbnail {
            url
            alt
          }
          thumbnail2x: thumbnail(size: 510) {
            url
          }
        }
      }
      quantity
      totalPrice {
        gross {
          amount
        }
      }
    }
    total {
      gross {
        amount
      }
    }
    subtotal {
      gross {
        amount
      }
    }
    shippingPrice {
      gross {
        amount
      }
    }
    discount {
      amount
    }
    discountName
    voucher {
      code
    }
    payments {
      id
      gateway
      isActive
      chargeStatus
      capturedAmount {
        amount
      }
      total {
        amount
      }
      creditCard {
        brand
        lastDigits
        expMonth
        expYear
      }
    }
  }
`

export const orderFragment = gql`
  ${checkoutOrderFragment}
  fragment OrderFields on Order {
    ...CheckoutOrderFields
    shippingMethod {
      id
      name
      price {
        amount
      }
    }
  }
`

export const checkoutQuery = gql`
  ${checkoutFragment}
  query Checkout($token: UUID!) {
    checkout(token: $token) {
      ...CheckoutFields
    }
  }
`

export const checkoutCreateMutation = gql`
  ${checkoutFragment}
  mutation CheckoutCreate($email: String, $channel: String) {
    checkoutCreate(input: {lines: [], email: $email, channel: $channel}) {
      created
      checkoutErrors {
        field
        message
        code
      }
      checkout {
        ...CheckoutFields
      }
    }
  }
`

export const checkoutLinesAddMutation = gql`
  mutation CheckoutLinesAdd($checkoutId: ID!, $lines: [CheckoutLineInput]!) {
    checkoutLinesAdd(checkoutId: $checkoutId, lines: $lines) {
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`

export const checkoutLinesUpdateMutation = gql`
  mutation CheckoutLinesUpdate(
    $checkoutId: ID!
    $lines: [CheckoutLineInput!]!
  ) {
    checkoutLinesUpdate(checkoutId: $checkoutId, lines: $lines) {
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`

export const myCheckoutQuery = gql`
  ${checkoutFragment}
  query MyCheckout {
    me {
      id
      checkout {
        ...CheckoutFields
      }
    }
  }
`

export const shopQuery = gql`
  query Shop {
    shop {
      defaultCountry {
        code
        country
      }
      countries {
        country
        code
      }
    }
  }
`

export const checkoutAddressesUpdateMutation = gql`
  mutation CheckoutAddressesUpdate(
    $checkoutId: ID!
    $billingAddress: AddressInput!
    $shippingAddress: AddressInput!
  ) {
    checkoutBillingAddressUpdate(
      checkoutId: $checkoutId
      billingAddress: $billingAddress
    ) {
      checkout {
        id
        billingAddress {
          id
          streetAddress1
          streetAddress2
        }
      }
      checkoutErrors {
        field
        message
        code
      }
    }
    checkoutShippingAddressUpdate(
      checkoutId: $checkoutId
      shippingAddress: $shippingAddress
    ) {
      checkout {
        id
        shippingAddress {
          id
          streetAddress1
          streetAddress2
        }
      }
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`

export const checkoutBillingAddressUpdateMutation = gql`
  mutation CheckoutBillingAddressUpdate(
    $checkoutId: ID!
    $billingAddress: AddressInput!
  ) {
    checkoutBillingAddressUpdate(
      checkoutId: $checkoutId
      billingAddress: $billingAddress
    ) {
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`

export const checkoutBillingAddressUpdateAnonymousMutation = gql`
  mutation CheckoutBillingAddressAnonymousUpdate(
    $checkoutId: ID!
    $billingAddress: AddressInput!
    $email: String!
  ) {
    checkoutBillingAddressUpdate(
      checkoutId: $checkoutId
      billingAddress: $billingAddress
    ) {
      checkoutErrors {
        field
        message
        code
      }
    }
    checkoutEmailUpdate(checkoutId: $checkoutId, email: $email) {
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`

export const checkoutAnonymousReceiverUpdateMutation = gql`
  mutation CheckoutAnonymousReceiverUpdate(
    $checkoutId: ID!
    $billingAddress: AddressInput!
    $shippingAddress: AddressInput!
    $email: String!
  ) {
    checkoutBillingAddressUpdate(
      checkoutId: $checkoutId
      billingAddress: $billingAddress
    ) {
      checkoutErrors {
        field
        message
        code
      }
    }
    checkoutShippingAddressUpdate(
      checkoutId: $checkoutId
      shippingAddress: $shippingAddress
    ) {
      checkoutErrors {
        field
        message
        code
      }
    }
    checkoutEmailUpdate(checkoutId: $checkoutId, email: $email) {
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`

export const checkoutShippingMethodUpdateMutation = gql`
  mutation CheckoutShippingMethodUpdate(
    $checkoutId: ID!
    $shippingMethodId: ID!
  ) {
    checkoutShippingMethodUpdate(
      checkoutId: $checkoutId
      shippingMethodId: $shippingMethodId
    ) {
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`

export const checkoutPaymentCreateMutation = gql`
  mutation CheckoutPaymentCreate($checkoutId: ID!, $input: PaymentInput!) {
    checkoutPaymentCreate(checkoutId: $checkoutId, input: $input) {
      payment {
        id
        gateway
        token
      }
      paymentErrors {
        field
        message
        code
      }
    }
  }
`

export const checkoutAddPromoCodeMutation = gql`
  mutation CheckoutAddPromoCode($checkoutId: ID!, $promoCode: String!) {
    checkoutAddPromoCode(checkoutId: $checkoutId, promoCode: $promoCode) {
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`

export const checkoutRemovePromoCodeMutation = gql`
  mutation CheckoutRemovePromoCode($checkoutId: ID!, $promoCode: String!) {
    checkoutRemovePromoCode(checkoutId: $checkoutId, promoCode: $promoCode) {
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`

export const checkoutCompleteMutation = gql`
  ${checkoutOrderFragment}
  mutation CheckoutComplete($checkoutId: ID!, $paymentData: JSONString) {
    checkoutComplete(checkoutId: $checkoutId, paymentData: $paymentData) {
      order {
        ...CheckoutOrderFields
      }
      confirmationNeeded
      confirmationData
      checkoutErrors {
        field
        message
        code
      }
    }
  }
`

export const orderByTokenQuery = gql`
  ${orderFragment}
  query OrderByToken($token: UUID!) {
    orderByToken(token: $token) {
      ...OrderFields
    }
  }
`
