import React from 'react'
import {useState} from 'react'

import cn from 'classnames'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

interface Props {
  tabs: string[]
  children: React.ReactNode[]
}

const Block = styled.div``

const Tab = styled.button`
  font-size: 1rem;
  position: relative;
  font-weight: 500;
  color: ${palette('ui60Solid')};
  padding: 8px 4px;
  text-align: center;
  border: 0;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
  transition: color 0.15s;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    background-color: ${palette('purple')};
    bottom: -4px;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &.isActive {
    color: ${palette('blue')};

    &:after {
      opacity: 1;
    }
  }

  &:hover {
    &:after {
      opacity: 1 !important;
    }
  }
`

const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 32px;

  &:hover {
    & ${Tab}.isActive:after {
      opacity: 0;
    }
  }

  ${media.md`
    margin-bottom: 48px;
  `}
`

const TabContent = styled.div`
  display: none;

  &.isActive {
    display: block;
  }
`

export default function TabsBlock({tabs, children}: Props): JSX.Element {
  const [activeTab, setActiveTab] = useState<number>(0)

  return (
    <Block>
      <TabsWrapper>
        {tabs.map((label, i) => (
          <Tab
            key={i}
            className={cn({isActive: activeTab === i})}
            onClick={() => setActiveTab(i)}
          >
            {label}
          </Tab>
        ))}
      </TabsWrapper>
      {React.Children.map(children, (child, i) => (
        <TabContent key={i} className={cn({isActive: activeTab === i})}>
          {child}
        </TabContent>
      ))}
    </Block>
  )
}
