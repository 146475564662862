import {fluidRange as polishedRange} from 'polished'
import {ThemeProps} from 'styled-bootstrap-grid'
import {css} from 'styled-components'

import gridTheme from '../themes/gridTheme'

type ThemeSizes = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'

export function fluidRange(
  property: string,
  from: string,
  to: string,
  minScreen: ThemeSizes = 'sm',
  maxScreen: ThemeSizes = 'lg',
  theme: ThemeProps['gridTheme'] = gridTheme,
) {
  return css`
    ${polishedRange(
      {
        prop: property,
        fromSize: from,
        toSize: to,
      },
      `${theme.breakpoints[minScreen]}px`,
      `${theme.breakpoints[maxScreen]}px`,
    )}
  `
}

// TODO: Test
export function setBodyOverflow(overflow: 'hidden' | 'unset') {
  document.body.style.overflow = overflow
}
