import styled, {css, keyframes} from 'styled-components'

interface Props {
  width?: number
}

const keyframesShimmer = keyframes`
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
`

const shimmerAnimation = css`
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 80vw 100%;
  animation: ${keyframesShimmer} 2s infinite linear;
`

export const ShimmerLoader = styled.div<Props>`
  height: 10px;
  background: #777;
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 8px;
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  ${shimmerAnimation}
`
