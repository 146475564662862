import React from 'react'

import {palette} from 'styled-tools'
import styled from 'styled-components'
import * as Popover from '@radix-ui/react-popover'

const Root = styled(Popover.Root)``

const Trigger = styled(Popover.Trigger)`
  border-radius: 8px;

  &[data-state='open'] {
    background-color: ${palette('ui10Solid')};
  }
`

const Portal = styled(Popover.Portal)``

const Content = styled(Popover.Content)`
  position: relative;
  z-index: 1000;
  background: ${palette('white')};
  border: 1px solid ${palette('border')};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

interface Props {
  trigger: React.ReactNode
  content: React.ReactNode
}

export default function CustomPopover({trigger, content}: Props) {
  return (
    <Root>
      <Trigger asChild>
        <div>{trigger}</div>
      </Trigger>

      <Portal>
        <Content align="end" sideOffset={4} collisionPadding={16}>
          {content}
        </Content>
      </Portal>
    </Root>
  )
}
