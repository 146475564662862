import React from 'react'

import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import styled, {css} from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '../../themes'

const textButtonStyles = css`
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  line-height: 1.3;

  svg {
    display: block;
    margin-top: 2px;
    margin-right: 12px;
    transition: transform 0.15s ease-out;
  }

  &:hover {
    svg {
      transform: translateX(5px) scale(1.1);
    }
  }
`

const Anchor = styled.a`
  ${textButtonStyles}
`

const Btn = styled.button`
  ${textButtonStyles}
`

export const ArrowButton = ({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <Btn {...props}>
    <ArrowRightSLineIcon size={24} color={styledTheme.palette.blue} />
    {children}
  </Btn>
)

const ArrowAnchor = React.forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>(function ArrowAnchor(
  {children, ...props}: React.AnchorHTMLAttributes<HTMLAnchorElement>,
  ref,
) {
  return (
    <Anchor {...props} ref={ref}>
      <ArrowRightSLineIcon size={20} color={styledTheme.palette.lightBlue} />
      {children}
    </Anchor>
  )
})

export default ArrowAnchor
