import cn from 'classnames'
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {Expandable} from '@festi/common/components/common'
import {styledTheme} from '@festi/common/themes'
import {media} from 'styled-bootstrap-grid'

const DropdownRow = styled.div`
  border: 1px solid ${palette('border')};
  margin-bottom: 12px;

  &:hover {
    border-color: ${palette('lightBlue')};
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.1);
  }
`

const DropdownOpen = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 22px 30px;
  cursor: pointer;

  &.expand {
    border-bottom: 1px solid ${palette('border')};
  }
`

const ExpandContainer = styled.div`
  position: relative;
`

const ArrowWrapper = styled.div`
  position: absolute;
  transform: rotate(0deg);
  transition: all 0.1s ease-in;

  .remixicon-icon {
    fill: ${palette('ui20Solid')};

    &:hover {
      fill: ${palette('lightBlue')};
    }
  }

  &.expand {
    transform: rotate(180deg);

    .remixicon-icon {
      fill: ${palette('lightBlue')};
    }
  }

  ${media.sm`
    position: relative;
  `}
`

interface ArrowProps {
  expand?: boolean
}

export function TableArrow({expand}: ArrowProps) {
  return (
    <ArrowWrapper className={cn({expand})}>
      <ArrowDownSLineIcon color={styledTheme.palette.ui50Solid} />
    </ArrowWrapper>
  )
}

interface Props {
  expand: boolean
  content?: React.ReactNode
  children?: React.ReactNode
  setExpand: () => void
}

export default function TableDropdown({
  expand,
  content,
  children,
  setExpand,
}: Props) {
  return (
    <DropdownRow>
      <DropdownOpen className={cn({expand})} onClick={setExpand}>
        {children}
      </DropdownOpen>

      <ExpandContainer>
        <Expandable expanded={expand}>{content}</Expandable>
      </ExpandContainer>
    </DropdownRow>
  )
}
