import React, {useCallback, useState} from 'react'

import cn from 'classnames'
import Link from 'next/link'
import {useWindowSize} from 'react-use'
import InformationFillIcon from 'remixicon-react/InformationFillIcon'
import InformationLineIcon from 'remixicon-react/InformationLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {gridTheme} from '@festi/common/themes'

import {P} from '../typography'

interface InfoSectionProps {
  info: string
  hoverOnDesktop?: boolean
  link?: string
  linkLabel?: string
  stickRightOnMobile?: boolean
}

interface InfoAdornmentProps {
  info: string
  link?: string
  linkLabel?: string
}

export const InfoBox = styled.div`
  position: absolute;
  left: 0px;
  background-color: ${palette('white')};
  border: 1px solid ${palette('ui20Solid')};
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.1);
  padding: 16px;
  line-height: 1.2;
  z-index: 1;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s;

  &.showInfo {
    opacity: 1;
    visibility: visible;
  }

  ${media.md`
    top: 18px;
    bottom: inherit;
    left: 18px;
    width: 250px;
  `}
`

const InfoButton = styled.a`
  cursor: pointer;

  &.stickRightOnMobile {
    position: absolute;
    top: calc(50% - 9px);
    right: 16px;
    z-index: 1;
  }

  ${media.md`
    &.stickRightOnMobile {
      position: relative;
      top: 0;
      right: 0;
    }
`}
`

const InfoAnchor = styled(Link)`
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('lightBlue')};
  border-bottom: 2px solid ${palette('lightBlue')};
  padding: 0;
  cursor: pointer;
  background: none;
  transition: color 0.15s, border-color 0.15s;

  &:hover {
    color: ${palette('lightBlue60')};
    border-color: ${palette('lightBlue60')};
  }
`

const InfoContainer = styled.div`
  margin-left: 6px;
  margin-right: 6px;

  ${media.md`
    position: relative;
  `}
`

const InfoAdornmentWrapper = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${InfoBox} {
    width: 360px;
  }

  ${media.md`
    position: absolute;
    top: calc(50% - 9px);
    right: 16px;
  `}
`

export function InfoAdornment({info, link, linkLabel}: InfoAdornmentProps) {
  return (
    <InfoAdornmentWrapper>
      <InfoSection
        info={info}
        link={link}
        linkLabel={linkLabel}
        stickRightOnMobile
      />
    </InfoAdornmentWrapper>
  )
}

export function InfoSection({
  info,
  link,
  linkLabel,
  hoverOnDesktop = false,
  stickRightOnMobile = false,
}: InfoSectionProps) {
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const {width} = useWindowSize()
  const isMobile = width < gridTheme.breakpoints.md
  const Icon = showInfo ? InformationFillIcon : InformationLineIcon

  const handleClick = useCallback(() => {
    if (isMobile || !hoverOnDesktop) {
      setShowInfo(!showInfo)
    }
  }, [isMobile, showInfo, hoverOnDesktop])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        setShowInfo(!showInfo)
      }
    },
    [showInfo],
  )

  const handleMouseEnter = useCallback(() => {
    if (!isMobile && hoverOnDesktop) {
      setShowInfo(true)
    }
  }, [isMobile, hoverOnDesktop])

  const handleMouseLeave = useCallback(() => {
    if (!isMobile && hoverOnDesktop) {
      setShowInfo(false)
    }
  }, [isMobile, hoverOnDesktop])

  return (
    <InfoContainer>
      <InfoButton
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-label="info"
        className={cn({stickRightOnMobile})}
      >
        <Icon size={18} />
      </InfoButton>
      <InfoBox className={cn({showInfo})}>
        <P>
          {info}{' '}
          {!!link && !!linkLabel && (
            <InfoAnchor
              target="_blank"
              aria-label={linkLabel}
              rel="noreferrer"
              href={link}
            >
              {linkLabel}
            </InfoAnchor>
          )}
        </P>
      </InfoBox>
    </InfoContainer>
  )
}
