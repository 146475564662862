import React from 'react'

import {animated, useSpring} from '@react-spring/web'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {buttonStyles, buttonTextStyles} from './buttonStyles'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  accentColor?: 'green' | 'lightBlue'
  icon?: RemixiconReactIconComponentType
  withBar?: boolean
  loading?: boolean
}

interface LoadingProps {
  accentColor: 'green' | 'lightBlue'
  loading: boolean
}

interface TextProps {
  withIcon?: boolean
}

interface LoadingBarProps {
  accentcolor: 'green' | 'lightBlue' | 'pink' | 'blue'
}

const Btn = styled.button<Props>`
  ${(p) => buttonStyles({accentColor: p.accentColor})}
`

const BtnText = styled.span<TextProps>`
  ${buttonTextStyles}
`

export const LoadingContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  overflow: hidden;
`

export const LoadingBar = styled(animated.div)<LoadingBarProps>`
  position: absolute;
  background-color: ${(p) => palette(p.accentcolor)};
  bottom: 0;
  left: 0;
  height: 4px;
  width: 80%;
`

export function ButtonLoading({
  loading,
  accentColor,
}: LoadingProps): JSX.Element {
  const loadingSpring = useSpring({
    from: {transform: 'translateX(-120%)'},
    to: {transform: 'translateX(20%)'},
    config: {mass: 1, tension: 16, friction: 0},
  })
  return (
    <LoadingContainer style={{opacity: loading ? 1 : 0}}>
      <LoadingBar style={loadingSpring} accentcolor={accentColor} />
    </LoadingContainer>
  )
}

export default function Button({
  children,
  icon: Icon,
  accentColor = 'lightBlue',
  loading = false,
  disabled,
  ...props
}: Props): JSX.Element {
  return (
    <Btn disabled={disabled || loading} accentColor={accentColor} {...props}>
      <BtnText withIcon={!!Icon}>{loading ? 'Augnablik...' : children}</BtnText>
      {!!Icon && <Icon size={24} />}
      <ButtonLoading accentColor={accentColor} loading={loading} />
    </Btn>
  )
}
