import React from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'

import {buttonStyles, buttonTextStyles} from './buttonStyles'

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  accentColor?: 'green' | 'lightBlue'
  darkMode?: boolean
  icon?: RemixiconReactIconComponentType
  withBar?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

interface TextProps {
  withIcon?: boolean
}

export const Anchor = styled.a<Props>`
  ${(p) => buttonStyles({accentColor: p.accentColor})}
`

const BtnText = styled.span<TextProps>`
  ${buttonTextStyles}
`

const AnchorButton = React.forwardRef<HTMLAnchorElement, Props>(
  function AnchorButton(
    {
      children,
      icon: Icon,
      accentColor = 'lightBlue',
      darkMode = false,
      ...props
    }: Props,
    ref,
  ) {
    return (
      <Anchor
        {...props}
        darkMode={darkMode}
        accentColor={accentColor}
        ref={ref}
      >
        <BtnText withIcon={!!Icon}>{children}</BtnText>
        {!!Icon && <Icon />}
      </Anchor>
    )
  },
)

export default AnchorButton
