import React from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'

import {actionButtonStyles, actionLabelStyles} from './buttonStyles'

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  icon: RemixiconReactIconComponentType
}

const Anchor = styled.a<Props>`
  ${actionButtonStyles}
`

const AnchorLabel = styled.span`
  ${actionLabelStyles}
`

const ActionAnchor = React.forwardRef<HTMLAnchorElement, Props>(
  function ActionAnchor({children, icon: Icon, ...props}: Props, ref) {
    return (
      <Anchor {...props} icon={Icon} ref={ref}>
        {!!Icon && <Icon />}
        <AnchorLabel>{children}</AnchorLabel>
      </Anchor>
    )
  },
)

export default ActionAnchor
