import {Auth} from '@aws-amplify/auth'

import settings from '@festi/common/constants/settings'

async function securityWorker() {
  const conf = {
    headers: {},
  }

  // get random number to prevent caching
  return new Promise((resolve, _reject) => {
    if (typeof window !== 'undefined') {
      // This will never set the Authorization header server side
      // as we do not want server side rendering to be impacted by
      // user specific data.
      Auth.currentSession()
        .then((session) => {
          Object.assign(conf.headers, {
            Authorization: `CognitoJWT ${session.getIdToken().getJwtToken()}`,
          })
          resolve(conf)
        })
        .catch((_err) => {
          // If there is no session, we do not want to set the header
          const backdoorToken = localStorage?.getItem('backdoorToken')
          if (backdoorToken) {
            Object.assign(conf.headers, {
              Authorization: `JWT ${backdoorToken}`,
            })
          }
          resolve(conf)
        })
    } else {
      resolve(conf)
    }
  })
}

export const restApiConf = {
  baseUrl: settings.saleorBaseUrl.replace(/\/$/, ''),
  securityWorker,
}
