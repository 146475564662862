import React from 'react'

import styled from 'styled-components'

import {iconButtonStyles} from './buttonStyles'

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  accentColor?: 'green' | 'lightBlue'
  large?: boolean
  icon?: React.ReactElement
}

const Anchor = styled.a<Props>`
  ${(p) => iconButtonStyles({accentColor: p.accentColor, large: p.large})}
`

const IconAnchorButton = React.forwardRef<HTMLAnchorElement, Props>(
  function IconAnchorButton({icon, ...props}: Props, ref) {
    return (
      <Anchor {...props} ref={ref}>
        {!!icon && icon}
      </Anchor>
    )
  },
)

export default IconAnchorButton
