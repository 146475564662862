import {CheckoutComplete_checkoutComplete_checkoutErrors} from "../api/saleor/types/CheckoutComplete"
import {CheckoutPaymentCreate_checkoutPaymentCreate_paymentErrors} from "../api/saleor/types/CheckoutPaymentCreate"

type PaymentError = CheckoutComplete_checkoutComplete_checkoutErrors | CheckoutPaymentCreate_checkoutPaymentCreate_paymentErrors

export const fieldErrorCodeMap: Record<string, string> = {
  UsernameExistsException: 'Notandi með þetta netfang er nú þegar til',
  UserNotFoundException: 'Notandi með þetta netfang fannst ekki',
  InvalidParameterException:
    'Því miður finnst notandi með þessar upplýsingar ekki hjá okkur',
  CodeMismatchException: 'Kóðinn sem þú skráðir inn er ekki réttur',
  NotAuthorizedException: 'Lykilorð passar ekki við notanda',
  UserNotConfirmedException:
    'Netfang hefur ekki verið staðfest, athugaðu tölvupóstinn þinn',
  UserCancelledException: 'Notandi hætti við',
}

export const checkoutPaymentErrorCodeMap: Record<string, string> = {
  INSUFFICIENT_FUNDS: 'Ekki heimild á korti',
  EXPIRED_CARD: 'Kortið er útrunnið',
  'Card expired': 'Kortið er útrunnið',
  INVALID_CARD_NUMBER: 'Kortanúmer er ekki rétt',
  INVALID_CARD_EXPIRATION: 'Gildistími ekki réttur',
  'Insufficient credit': 'Ekki er næg heimild fyrir þessum viðskiptum.',
  INVOICING_FOR_USER_NOT_ALLOWED_BY_BUSINESS: 'Notandi hefur ekki réttindi til þess að kaupa í reikning fyrir þetta fyrirtæki',
}

export const checkoutErrorCodeMap: Record<string, string> = {
  INSUFFICIENT_STOCK: 'Því miður er ekki til meira magn af þessari vöru',
  INVALID: 'Varan er ekki til sem stendur',
  INVALID_ADDRESS: 'Ógilt heimilisfang',
  SHIPPING_METHOD_NOT_SET: 'Afhendingamáti er ekki valinn',
  BILLING_ADDRESS_NOT_SET: 'Heimilisfang er ekki valið',
  INVALID_SHIPPING_METHOD: 'Því miður er sendingamáti ekki leyfilegur',
  QUANTITY_GREATER_THAN_LIMIT: 'Magn af vöru er meira en lagerstaða',
  SHIPPING_ADDRESS_NOT_SET: 'Afhendingastaður er ekki valinn',
  SHIPPING_METHOD_NOT_APPLICABLE: 'Sendingamáti er ekki virkur',
  DELIVERY_METHOD_NOT_APPLICABLE: 'Afhendingamáti er ekki virkur',
  VOUCHER_NOT_APPLICABLE: 'Kóði er ekki virkur',
  GIFT_CARD_NOT_APPLICABLE: 'Gjafakorter ekki virkt',
  EMAIL_NOT_SET: 'Netfang er ekki valið',
  CHECKOUT_NOT_FULLY_PAID: 'Greiðslu hafnað',
  'Card expired': 'Kortið er útrunnið',
}

export const checkoutOrPaymentError = (error: PaymentError) =>
  error?.code === 'PAYMENT_ERROR'
    ? checkoutPaymentErrorCodeMap?.[error?.message || ''] || `Villa í greiðslugátt: ${error?.message}`
    : checkoutErrorCodeMap[error?.code] || `Villa í körfu: ${error?.message}`

export const accountErrorCodeMap: Record<string, string> = {
  INVALID:
    'Því miður var ekki hægt að framkvæma aðgerð. Athugaðu hvort allir reitir séu rétt út fylltir',
}
