export function fromGlobalId(val: string) {
  const [model, id] = Buffer.from(val, 'base64').toString().split(':')
  return {model, id}
}

export function intFromGlobalId(val: string) {
  const {model, id} = fromGlobalId(val)
  return {model, id: parseInt(id, 10)}
}

export function toGlobalId(model: string, id: number | string) {
  return Buffer.from(`${model}:${id}`).toString('base64')
}
