import {StructuredText} from 'react-datocms'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {Webpage_notification_description as NotificationContent} from '@festi/common/api/datocms/types/WebPage'
import {DynamicIcon} from '@festi/common/components/common'

interface Props {
  icon?: string | null
  title?: string | null
  color?: string | null
  message?: NotificationContent | null
}

const Banner = styled.div`
  width: 100%;
  background-color: ${(props) => palette(props.color[1])};
  border-top: 1px solid ${(props) => palette(props.color[0])};
  border-bottom: 1px solid ${(props) => palette(props.color[0])};
  padding: 16px;
  display: flex;
  align-items: flex-start;
  .remixicon-icon {
    width: 24px;
    height: 24px;
    margin-right: 24px;
    fill: ${(props) => palette(props.color[0])};
  }

  ${media.md`
    padding: 16px 24px;
  `}
`

const BannerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${palette('blue')};
`

const Message = styled.span`
  font-size: 1rem;
  color: ${palette('ui60Solid')};
  padding-top: 8px;
`

export default function NotificationBanner({
  icon,
  title,
  color,
  message,
}: Props): JSX.Element {
  const styles = {
    red: ['error', 'errorBack'],
    yellow: ['warning', 'warningBack'],
    blue: ['lightBlue', 'lightBlueBack'],
    green: ['success', 'successBack'],
  }

  return (
    <Banner color={styles[color]}>
      {!!icon && <DynamicIcon icon={icon} size={24} />}
      <BannerContent>
        <Title>{title}</Title>
        {!!message && (
          <Message>
            <StructuredText data={message} />
          </Message>
        )}
      </BannerContent>
    </Banner>
  )
}
