import {useMemo} from 'react'

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {Auth} from '@aws-amplify/auth'
import urljoin from 'url-join'
import settings from '../../constants/settings'

let apolloClient: ApolloClient<NormalizedCacheObject>


export const httpLink = createHttpLink({
  uri: urljoin(settings.saleorBaseUrl, '/graphql/'),
})

export const authLink = setContext(async (_, context) => {
  try {
    const idToken = await (await Auth.currentSession())
      .getIdToken()
      .getJwtToken()
    if (idToken) {
      return {
        ...context,
        headers: {
          ...context.headers,
          Authorization: `CognitoJWT ${idToken}`,
        },
      }
    }
    const backdoorToken = typeof window !== 'undefined' && localStorage?.getItem('backdoorToken')
    if (backdoorToken) {
      return {
        ...context,
        headers: {
          ...context.headers,
          Authorization: `JWT ${backdoorToken}`,
        },
      }
    }
    return context
  } catch (err) {
    return context
  }
})

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
    defaultOptions: {
      watchQuery: {
        // TODO: Either of these fixes the loading issue, but is this bad?
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
  })
}

export function initializeApollo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: any = null,
): ApolloClient<NormalizedCacheObject> {
  const currApolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = currApolloClient.extract()

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    currApolloClient.cache.restore({...existingCache, ...initialState})
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return currApolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = currApolloClient
  return currApolloClient
}

export function useApollo(
  initialState?: unknown,
): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
