import React from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled, {css} from 'styled-components'
import {palette} from 'styled-tools'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: RemixiconReactIconComponentType
}

interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  icon?: RemixiconReactIconComponentType
}

const buttonStyles = css`
  display: flex;
  align-items: center;
  border-radius: 41px;
  padding: 12px 24px;
  background-color: ${palette('blue')};
  color: ${palette('white')};
  cursor: pointer;
  border: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;

  svg {
    fill: ${palette('green')};
    margin-left: 8px;
  }

  &.isOpen,
  &:hover:not(:disabled) {
    background-color: ${palette('green')};
    color: ${palette('blue')};

    svg {
      fill: ${palette('blue')};
    }
  }

  &:disabled {
    background-color: ${palette('ui30Solid')};

    svg {
      fill: ${palette('white')};
    }
  }
`

const Btn = styled.button`
  ${buttonStyles}
`

const AnchorBtn = styled.a`
  ${buttonStyles}
`

export default function BubbleButton({
  children,
  icon: Icon,
  disabled,
  ...props
}: ButtonProps): JSX.Element {
  return (
    <Btn disabled={disabled} {...props}>
      {children}
      {!!Icon && <Icon size={24} />}
    </Btn>
  )
}

export const AnchorBubbleButton = React.forwardRef<
  HTMLAnchorElement,
  AnchorProps
>(function AnchorBubbleButton(
  {children, icon: Icon, ...props}: AnchorProps,
  ref,
) {
  return (
    <AnchorBtn {...props} ref={ref}>
      {children}
      {!!Icon && <Icon />}
    </AnchorBtn>
  )
})
