import React from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'

import {actionButtonStyles, actionLabelStyles} from './buttonStyles'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: RemixiconReactIconComponentType
  center?: boolean
}

const Btn = styled.button<Props>`
  ${actionButtonStyles}
`

const BtnLabel = styled.span`
  ${actionLabelStyles}
`

export default function ActionButton({
  children,
  icon: Icon,
  ...props
}: Props): JSX.Element {
  return (
    <Btn {...props} icon={Icon}>
      {!!Icon && <Icon />}
      <BtnLabel>{children}</BtnLabel>
    </Btn>
  )
}
