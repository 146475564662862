import React from 'react'

import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import styled from 'styled-components'
import {palette, ifProp} from 'styled-tools'

import {styledTheme} from '../../themes'
import {checkButtonStyles} from './buttonStyles'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  checked?: boolean
}

interface CheckMarkProps {
  checked?: boolean
}

const Label = styled.span`
  flex-grow: 1;
  text-align: center;
  padding: 0 24px;
`

const CheckMark = styled.div<CheckMarkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border: 1px solid ${palette('lightBlue')};
  margin-right: 24px;
  background-color: ${ifProp(
    {checked: true},
    palette('lightBlue'),
    palette('white'),
  )};
  transition: background-color 0.1s;
`

const Button = styled.button`
  ${checkButtonStyles}
`

export default function CheckButton({
  children,
  checked,
  ...props
}: Props): JSX.Element {
  return (
    <Button {...props}>
      <Label>{children}</Label>
      <CheckMark checked={checked}>
        <CheckLineIcon size={12} color={styledTheme.palette.white} />
      </CheckMark>
    </Button>
  )
}
