import {css} from 'styled-components'
import {palette} from 'styled-tools'

export const hideScrollbar = css`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const styleScrollbar = css`
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${palette('ui10Solid')} ${palette('white')};

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: ${palette('white')};
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid ${palette('white')};
    border-radius: 2px;
    background-color: ${palette('ui10Solid')};
  }
`
