import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'

import sortBy from 'lodash/sortBy'
import {useRouter} from 'next/router'
import {toast} from 'react-toastify'
import {useLocalStorage} from 'react-use'
import useSWR from 'swr/immutable'

import {
  restApi,
  VariantList,
  VariantDetail,
  handleRestResponse,
  Variant,
  ProductDetail,
} from '../api/rest'
import settings from '../constants/settings'
import {onDutyFree} from '../constants/channels'
import {
  sendEcommerceAddToCompareEvent,
  sendEcommerceAddToCompareEventNew,
} from '../utils/tagmanager'
import {getListingPrice} from '../utils/price'

export const ComparisonContext = createContext<Partial<ComparisonContextProps>>(
  {},
)

interface ComparisonProviderProps {
  children: React.ReactNode
}

export const comparisonCap = 4

// TODO: Move storage to Rest API, instead of localStorage?
export function ComparisonProvider({children}: ComparisonProviderProps) {
  const router = useRouter()

  const [skus, setSkus] = useLocalStorage<string[]>(
    `comparedSkus${onDutyFree ? 'Dutyfree' : 'Webshop'}`,
  )
  const [comparisonSearchOpen, setComparisonSearchOpen] =
    useState<boolean>(false)

  const {data: productVariants, mutate} = useSWR<VariantDetail[]>(
    'comparison-product-variants',
  )
  useEffect(() => {
    mutate(async () =>
      skus?.length
        ? await handleRestResponse(
            restApi.productVariantsMultiList({
              channel: settings.channel,
              sku: [skus.join(',')],
            }),
          )
        : [],
    )
  }, [mutate, skus])

  const sortedProductVariants = sortBy(
    productVariants?.filter((v) => skus?.indexOf(v.sku || '') !== -1) ?? [],
    (v) => skus?.indexOf(v.sku || ''),
  )

  const existingSkus = useMemo(
    () => sortedProductVariants?.map((v) => v.sku || '') ?? [],
    [sortedProductVariants],
  )

  const comparedProducts =
    sortedProductVariants.map((p) => ({
      ...p,
      attributes: {...p.attributes, ...p.product.attributes},
    })) ?? []

  const replaceComparison = useCallback(
    (newSkus: string[]) => {
      if (newSkus?.length > comparisonCap) {
        toast.error(
          `Því miður er ekki hægt að hafa fleiri en ${comparisonCap} vörur í samanburði`,
        )
        return
      }
      setSkus(newSkus)
    },
    [setSkus],
  )

  const addToComparison = useCallback(
    (variant: VariantDetail | VariantList) => {
      sendEcommerceAddToCompareEvent(variant)
      replaceComparison([...existingSkus, variant?.sku || ''])
    },
    [existingSkus, replaceComparison],
  )

  const addToComparisonNew = useCallback(
    (product: ProductDetail, variant: Variant | VariantList) => {
      sendEcommerceAddToCompareEventNew(product, variant)
      replaceComparison([...existingSkus, variant?.sku || ''])
    },
    [existingSkus, replaceComparison],
  )

  const removeFromComparison = useCallback(
    (sku: string) => {
      setSkus(existingSkus.filter((s) => s !== sku))
    },
    [existingSkus, setSkus],
  )

  const clearComparison = useCallback(() => {
    setSkus([])
  }, [setSkus])

  const hasDiscount = !!comparedProducts?.find(
    (product) =>
      (getListingPrice(product)?.lowestPrice || 0) !==
      (getListingPrice(product)?.price || 0),
  )

  const toggleComparisonSearch = useCallback(() => {
    setComparisonSearchOpen(!comparisonSearchOpen)
  }, [comparisonSearchOpen, setComparisonSearchOpen])

  const isComparing =
    !!comparedProducts?.length &&
    router.pathname?.indexOf('samanburdur') === -1 &&
    router.pathname?.indexOf('kaup') === -1

  return (
    <ComparisonContext.Provider
      value={{
        hasDiscount,
        comparedSkus: skus || [],
        comparisonCap,
        comparedProducts: comparedProducts || [],
        comparisonLoading: comparedProducts == null,
        comparisonSearchOpen,
        clearComparison,
        addToComparison,
        addToComparisonNew,
        replaceComparison,
        removeFromComparison,
        toggleComparisonSearch,
        isComparing,
      }}
    >
      {children}
    </ComparisonContext.Provider>
  )
}

interface ComparisonContextProps {
  isComparing: boolean
  hasDiscount: boolean
  comparedSkus?: string[]
  comparisonCap: number
  comparedProducts?: VariantDetail[]
  comparisonLoading: boolean
  comparisonSearchOpen: boolean
  clearComparison: () => void
  addToComparison: (sku: VariantDetail | VariantList) => void
  addToComparisonNew: (
    product: ProductDetail,
    variant: Variant | VariantList,
  ) => void
  replaceComparison: (newSkus: string[]) => void
  removeFromComparison: (sku: string) => void
  toggleComparisonSearch: () => void
}

export function useComparison() {
  return useContext(ComparisonContext) as ComparisonContextProps
}
