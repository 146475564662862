import React from 'react'

import {RemixiconReactIconComponentType} from 'remixicon-react'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'

interface Props {
  icon: RemixiconReactIconComponentType
  children: React.ReactNode
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
`

const InnerWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .remixicon-icon {
    margin-bottom: 12px;
  }
`

const Message = styled.span`
  font-size: 1.125rem;
  color: ${palette('ui40Solid')};
  text-align: center;
  line-height: 1.1;
`

export default function EmptyResults({
  icon: Icon,
  children,
}: Props): JSX.Element {
  return (
    <Wrapper>
      <InnerWrapper>
        {<Icon size={24} color={styledTheme.palette.ui40Solid} />}
        <Message>{children}</Message>
      </InnerWrapper>
    </Wrapper>
  )
}
