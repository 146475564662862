import React from 'react'

import {useSpring, animated} from '@react-spring/web'
import styled from 'styled-components'
import {palette} from 'styled-tools'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
`

const LoadingDot = styled(animated.div)`
  border-radius: 50%;
  background-color: ${palette('lightBlue20')};
  display: block;
  height: 20px;
  width: 20px;
  margin: 8px;
`

export default function DotsLoader(): JSX.Element {
  const from = {transform: 'scale(1)'}
  const to = {transform: 'scale(1.12)'}
  const config = {mass: 1, tension: 80, friction: 0}

  const spring1 = useSpring({
    from,
    to,
    config,
  })

  const spring2 = useSpring({
    from,
    to,
    config,
    delay: 200,
  })

  const spring3 = useSpring({
    from,
    to,
    config,
    delay: 300,
  })

  return (
    <Wrapper>
      <LoadingDot style={spring1} />
      <LoadingDot style={spring2} />
      <LoadingDot style={spring3} />
    </Wrapper>
  )
}
