const gridTheme = {
  gridColumns: 12,
  breakpoints: {
    xxl: 3000,
    xl: 1940,
    lg: 1460,
    md: 1040,
    sm: 600,
    xs: 360,
  },
  row: {
    padding: 8,
  },
  col: {
    padding: 8,
  },
  container: {
    padding: 16,
    maxWidth: {
      xxl: 1616,
      xl: 1616,
      lg: 1616,
      md: 1616,
      sm: 1616,
      xs: 1616,
    },
  },
}

export default gridTheme
