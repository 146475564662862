import React, {useRef} from 'react'

import {Transition} from 'react-transition-group'

interface MapProps<L> {
  [key: string]: L
}

interface Props {
  expanded?: boolean
  minHeight?: number
  children: React.ReactNode
}

const defaultStyle = {
  transition: 'height 0.24s',
  width: '100%',
  transitionTimingFunction: 'cubic-bezier(0.2, 0.16, 0.14, 1)',
}

export default function Expandable({
  expanded,
  minHeight,
  children,
}: Props): JSX.Element {
  const contentRef: React.RefObject<HTMLDivElement> = useRef(null)

  return (
    <Transition
      nodeRef={contentRef}
      in={expanded}
      timeout={{enter: 240, exit: 0}}
    >
      {(state) => {
        const targetHeight = contentRef.current?.clientHeight
        const heightMap: MapProps<number | undefined | 'auto'> = {
          entering: targetHeight,
          entered: 'auto',
          exiting: targetHeight,
          exited: minHeight || 0,
        }

        const visibilityMap: MapProps<'visible' | 'hidden'> = {
          entering: 'visible',
          entered: 'visible',
          exiting: 'visible',
          exited: minHeight ? 'visible' : 'hidden',
          unmounted: 'hidden',
        }

        const overFlowMap: MapProps<'initial' | 'hidden'> = {
          entering: 'hidden',
          entered: 'initial',
          exiting: 'initial',
          exited: 'hidden',
          unmounted: 'hidden',
        }

        return (
          <div
            style={{
              ...defaultStyle,
              height: heightMap[state],
              visibility: visibilityMap[state],
              overflow: overFlowMap[state],
            }}
          >
            <div ref={contentRef}>{children}</div>
          </div>
        )
      }}
    </Transition>
  )
}
