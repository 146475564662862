import React, {
  useEffect,
  createContext,
  useState,
  useCallback,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'

import {Auth} from '@aws-amplify/auth'
import {Hub, HubCallback} from '@aws-amplify/core'
import {CognitoUser as CognitoUserOrig} from 'amazon-cognito-identity-js'
import {useEffectOnce, useLocalStorage} from 'react-use'
import useSWR, {KeyedMutator} from 'swr'

import {sendDataLayerUserEvent} from '@festi/common/utils/tagmanager'

import {
  restApi,
  Credit,
  Address,
  Business,
  ElkoUser,
  handleRestResponse,
  PaginatedBusinessList,
  BusinessMemberRoleEnum,
} from '../api/rest'
import {deleteCognitoCookiesWithAnotherClientId} from '../utils/cookies'

interface CognitoUser extends CognitoUserOrig {
  signInUserSession: {
    idToken: {
      jwtToken: string
      payload: {
        client_id: string
        event_id: string
        auth_time: number
        exp: number
        iat: number
        username: string
      }
    }
  }
  attributes: {sub: string}
}

interface UserContextProps {
  idToken?: string
  userLoading?: boolean
  user?: ElkoUser
  business?: Business
  businesses?: PaginatedBusinessList
  businessInvoice?: Credit
  loadingBusiness?: boolean
  selectedBusiness?: string
  isUserAdmin?: boolean
  isUserViewOnly?: boolean
  cognitoUser?: CognitoUser
  missingAttributes?: boolean
  lastLogin?: Date
  addresses?: Address[]
  addressesLoading?: boolean
  promptAuth: boolean
  fetchUser: () => void
  setLastLogin: React.Dispatch<React.SetStateAction<Date | undefined>>
  setPromptAuth: Dispatch<SetStateAction<boolean>>
  mutateBusiness: KeyedMutator<Business>
  mutateBusinesses: KeyedMutator<PaginatedBusinessList>
  mutateBusinessInvoice: KeyedMutator<Credit>
  mutateAllAddresses: KeyedMutator<Address[]>
  handleLoadingBusiness: (ssn?: string) => void
}

export const UserContext = createContext<Partial<UserContextProps>>({})

interface UserProviderProps {
  children: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}

export function UserProvider({children}: UserProviderProps) {
  const [cognitoUserLoading, setCognitoUserLoading] = useState<boolean>(true)
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>()
  const [userLoading, setUserLoading] = useState<boolean>(true)
  const [promptAuth, setPromptAuth] = useState<boolean>(false)
  const [selectedBusiness, setSelectedBusiness] = useState<string>()
  const [businessLoading, setBusinessLoading] = useState(true)
  const [addressesLoading, setAddressesLoading] = useState(true)
  const [switchsingSSN, setSwitchingSSN] = useState<boolean>(false)

  const [lastLogin, setLastLogin] = useLocalStorage<Date>(
    'lastLogin',
    new Date(),
  )
  const backdoorToken =
    typeof window !== 'undefined' && localStorage?.getItem('backdoorToken')
  // Note: This is the idToken, not the accessToken. Should not be used to access third party APIs
  // If we are accessing third party APIs, we should use the accessToken
  const idToken =
    cognitoUser?.signInUserSession?.idToken?.jwtToken ?? backdoorToken

  // const userSession = cognitoUser?.signInUserSession?.idToken?.payload
  // AWS uses Unix timestamps, which are in seconds, not milliseconds
  //const lastLogin = userSession?.iat
  //  ? new Date(userSession.iat * 1000)
  //  : undefined

  const {data: user, mutate: fetchUser} = useSWR(
    idToken ? [idToken, 'user'] : undefined,
    () => handleRestResponse(restApi.usersMeRetrieve()),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      onSuccess: () => {
        setUserLoading(false)

        sendDataLayerUserEvent({
          user_type: 'person',
          user_logged_in: 'rafraen_skilriki',
        })
      },
    },
  )

  const {data: businesses, mutate: mutateBusinesses} = useSWR(
    user?.id ? [user.id, 'businessesList'] : undefined,
    () => handleRestResponse(restApi.businessesList()),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      onSuccess: (res) => {
        setBusinessLoading(false)
        sendDataLayerUserEvent({
          user_type: selectedBusiness ? 'business' : 'person',
          user_logged_in: 'rafraen_skilriki',
          user_has_business: !!res?.results?.length,
          user_business_has_credit: business?.invoicingAllowed,
        })
      },
    },
  )

  const {data: business, mutate: mutateBusiness} = useSWR(
    user?.id ? [user.id, 'business'] : undefined,
    () => handleRestResponse(restApi.usersMeBusinessRetrieve()),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      onSuccess: (res) => {
        setSelectedBusiness(res.ssn)

        sendDataLayerUserEvent({
          event: switchsingSSN ? 'user_switch_ssn' : undefined,
          user_type: res?.id ? 'business' : 'person',
          user_logged_in: 'rafraen_skilriki',
          user_has_business: !!businesses?.results?.length,
          user_business_has_credit: res?.invoicingAllowed,
        })

        setSwitchingSSN(false)
        setBusinessLoading(false)
      },
    },
  )

  const loadingBusiness =
    !!user &&
    (businessLoading ||
      (!!selectedBusiness && !business?.id) ||
      !businesses?.results)

  const userRole = business?.members?.find((m) => m.ssn === user?.ssn)?.role
  const isUserAdmin =
    !!userRole &&
    [
      BusinessMemberRoleEnum.PowerOfAttorney,
      BusinessMemberRoleEnum.Admin,
    ].includes(userRole)
  const isUserViewOnly = userRole === BusinessMemberRoleEnum.ViewingPrivilege

  const {data: businessInvoice, mutate: mutateBusinessInvoice} = useSWR(
    business?.invoicingAllowed
      ? [business.id, business.invoicingAllowed, 'businessCredit']
      : undefined,
    ([id]) => handleRestResponse(restApi.businessesCreditRetrieve(id)),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      onSuccess: () => setBusinessLoading(false),
    },
  )

  const {data: addresses, mutate: mutateAllAddresses} = useSWR(
    user?.id ? [user.id, business?.id, 'usersMeAddressesList'] : undefined,
    () => handleRestResponse(restApi.usersMeAddressesList()),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      onSuccess: () => setAddressesLoading(false),
    },
  )

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCognitoUser(user)
      })
      .catch(noop)
      .finally(() => {
        setCognitoUserLoading(false)
      })

    const hubCallback: HubCallback = ({payload: {event, data}}) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          setCognitoUser(data)
          break
        case 'signOut':
          setCognitoUser(undefined)
          sendDataLayerUserEvent({
            event: 'logout',
            user_type: 'guest',
            user_logged_in: 'guest',
          })
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break
        case 'tokenRefresh_failure':
          Auth.signOut()
          setCognitoUser(undefined)
          sendDataLayerUserEvent({
            event: 'logout',
            user_type: 'guest',
            user_logged_in: 'guest',
          })
          break
        case 'tokenRefresh':
          Auth.currentAuthenticatedUser().then((user) => {
            setCognitoUser(user)
          })
          break
      }
    }

    // We must have reference to the callback to remove/unlisten
    Hub.listen('auth', hubCallback)
    return () => {
      Hub.remove('auth', hubCallback)
    }
  }, [setCognitoUser, setCognitoUserLoading])

  useEffect(() => {
    // If not logged in, we disable loading states
    if (!idToken && !cognitoUserLoading) {
      setUserLoading(false)
      setBusinessLoading(false)
      setAddressesLoading(false)
    }
  }, [idToken, cognitoUserLoading])

  const handleLoadingBusiness = useCallback((ssn?: string) => {
    setSwitchingSSN(true)
    setBusinessLoading(true)
    setSelectedBusiness(ssn)
  }, [])

  useEffectOnce(() => {
    deleteCognitoCookiesWithAnotherClientId()
  })

  return (
    <UserContext.Provider
      value={{
        idToken,
        userLoading: cognitoUserLoading || userLoading,
        user,
        business,
        businesses,
        businessInvoice,
        loadingBusiness,
        selectedBusiness,
        isUserAdmin,
        isUserViewOnly,
        cognitoUser,
        missingAttributes:
          user?.personalizationAccepted === null ||
          user?.generalTermsAccepted === null ||
          user?.postlistAccepted === null ||
          (!user?.generalTermsAccepted && !user?.email),
        lastLogin,
        addresses,
        addressesLoading,
        // FIXME: What is promptAuth and why does it need to be part of UserContext?
        promptAuth,
        fetchUser,
        setLastLogin,
        setPromptAuth,
        mutateBusiness,
        mutateBusinesses,
        mutateBusinessInvoice,
        mutateAllAddresses,
        handleLoadingBusiness,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useAuth() {
  return useContext(UserContext) as UserContextProps
}
