import React, {
  useEffect,
  createContext,
  useContext,
  useCallback,
  useState,
} from 'react'

import {useMutation, useQuery} from '@apollo/client'
import {formatISO} from 'date-fns'
import flatten from 'lodash/flatten'
import fromPairs from 'lodash/fromPairs'
import mapValues from 'lodash/mapValues'
import times from 'lodash/times'
import toPairs from 'lodash/toPairs'
import values from 'lodash/values'
import Link from 'next/link'
import {toast} from 'react-toastify'
import {useLocalStorage} from 'react-use'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import useSWR from 'swr'
import {useDebouncedCallback} from 'use-debounce'
import {v4 as uuidv4} from 'uuid'
import {onDutyFree} from '../constants/channels'
import {
  Checkout as RestCheckout,
  AvailableInsurance,
  CheckoutDeliveryRequest,
  AddInsuranceRequest,
  ShippingMethodChannelListing,
} from '@festi/common/api/rest'
import {
  CheckoutLine,
  CheckoutSalesType,
} from '@festi/common/api/rest/data-contracts'
import {CheckoutShippingMethodUpdate} from '@festi/common/api/saleor/types/CheckoutShippingMethodUpdate'
import {isOutOfStockWeb} from '@festi/common/utils/products'

import {handleRestResponse, restApi} from '../api/rest'
import {
  checkoutAddressesUpdateMutation,
  checkoutCreateMutation,
  checkoutLinesUpdateMutation,
  myCheckoutQuery,
  checkoutQuery,
  checkoutShippingMethodUpdateMutation,
  checkoutPaymentCreateMutation,
  checkoutCompleteMutation,
  checkoutAnonymousReceiverUpdateMutation,
  checkoutBillingAddressUpdateMutation,
  checkoutBillingAddressUpdateAnonymousMutation,
} from '../api/saleor/queries/checkout-queries'
import {Checkout} from '../api/saleor/types/Checkout'
import {
  CheckoutAddressesUpdate,
  CheckoutAddressesUpdateVariables,
} from '../api/saleor/types/CheckoutAddressesUpdate'
import {CheckoutAnonymousReceiverUpdate} from '../api/saleor/types/CheckoutAnonymousReceiverUpdate'
import {CheckoutBillingAddressAnonymousUpdate} from '../api/saleor/types/CheckoutBillingAddressAnonymousUpdate'
import {CheckoutBillingAddressUpdate} from '../api/saleor/types/CheckoutBillingAddressUpdate'
import {
  CheckoutComplete,
  CheckoutComplete_checkoutComplete,
} from '../api/saleor/types/CheckoutComplete'
import {CheckoutCreate} from '../api/saleor/types/CheckoutCreate'
import {
  CheckoutLinesUpdate,
  CheckoutLinesUpdateVariables,
  CheckoutLinesUpdate_checkoutLinesUpdate,
  CheckoutLinesUpdate_checkoutLinesUpdate_checkoutErrors,
} from '../api/saleor/types/CheckoutLinesUpdate'
import {
  CheckoutPaymentCreate,
  CheckoutPaymentCreateVariables,
  CheckoutPaymentCreate_checkoutPaymentCreate,
} from '../api/saleor/types/CheckoutPaymentCreate'
import {AddressInput, PaymentInput} from '../api/saleor/types/global'
import {checkoutErrorCodeMap} from '../constants'
import {checkoutOrPaymentError} from '../constants/errorCodes'
import settings from '../constants/settings'
import {EMPTY_SSN, getCartQuantityMap} from '../utils/checkout'
import {toGlobalId} from '../utils/graphql'
import {useAuth} from './UserContext'

interface CheckoutContextProps {
  restCheckout: RestCheckout
  restCheckoutLoading: boolean
  restCheckoutIsValidating: boolean
  restCheckoutId: string
  cartState: Record<string, number>
  salesType?: CheckoutSalesType[]
  salesTypeLoading?: boolean
  cartUpdate: (
    state: Record<string, number>,
  ) => Promise<CheckoutLinesUpdate_checkoutLinesUpdate>
  cartClear: () => void
  cartIsUpdating: boolean
  updateAddresses: (
    sAddr: AddressInput,
    bAddr: AddressInput,
  ) => Promise<CheckoutAddressesUpdate>
  updateBillingAddress: (
    bAddr: AddressInput,
  ) => Promise<CheckoutBillingAddressUpdate>
  updateShippingMethod: (id: number) => Promise<void>
  createPayment: (
    input: PaymentInput,
  ) => Promise<CheckoutPaymentCreate_checkoutPaymentCreate>
  completeCheckout: (
    paymentData: string,
  ) => Promise<CheckoutComplete_checkoutComplete>
  updateInsurances: (
    variantSku: string,
    insurance: AvailableInsurance,
    qty: number,
  ) => Promise<void>
  hasInsurableLines: boolean
  hasInsurances: boolean
  shippingMethodChannelListing: ShippingMethodChannelListing
  updateDeliverySlot: (slotData: CheckoutDeliveryRequest) => Promise<void>
  updateAddressesAnonymous: (
    sAddr: AddressInput,
    bAddr: AddressInput,
    email: string,
  ) => Promise<CheckoutAnonymousReceiverUpdate>
  updateBillingAddressAnonymous: (
    bAddr: AddressInput,
    email: string,
  ) => Promise<CheckoutBillingAddressAnonymousUpdate>
  anonymousToken: string
  refetchRestCheckout: () => void
  removeAnonymousToken: () => void
  completeDutyFreeOrder: (
    flightNumber: string,
    flightDate: Date,
  ) => Promise<string>
  setCheckoutNoteMsg: (noteMsg: string) => Promise<RestCheckout>
}

export const CheckoutContext = createContext<Partial<CheckoutContextProps>>({})

interface CheckoutProviderProps {
  children: React.ReactNode
}

let initializedCart = false

// a backup of the previous cartState to fall back on in case something goes wrong during cart update
let prevCartState: Record<string, number> | undefined

const ToastLink = styled.a`
  color: ${palette('green')};
`

export function CheckoutProvider({children}: CheckoutProviderProps) {
  const {user, missingAttributes} = useAuth()
  const [cartState, setCartState] = useState<Record<string, number>>()
  const [cartIsUpdating, setIsCartUpdating] = useState<boolean>(false)

  const [anonymousToken, setAnonymousToken, removeAnonymousToken] =
    useLocalStorage<string>('anonymousCartToken')

  // TODO: move to rest api if possible
  const {data: anonCheckoutData} = useQuery<Checkout>(checkoutQuery, {
    skip: !anonymousToken,
    variables: {
      token: anonymousToken,
    },
  })

  const {
    data: restCheckout,
    isValidating: restCheckoutIsValidating,
    isLoading: restCheckoutLoading,
    mutate: refetchRestCheckout,
  } = useSWR(
    anonCheckoutData?.checkout || user
      ? ['checkout', user ? 'current' : anonCheckoutData?.checkout?.token]
      : null,
    ([_, token]) =>
      handleRestResponse(
        restApi.checkoutsRetrieve(token, {
          channel: settings.channel,
        }),
      ),
    {
      revalidateOnFocus: false,
    },
  )

  const checkoutToken = restCheckout?.token || anonymousToken

  const {data: salesType, isLoading: salesTypeLoading} = useSWR(
    checkoutToken ? [checkoutToken, restCheckout, 'sales-type'] : null,
    ([token]) =>
      handleRestResponse(
        restApi.checkoutsSalesTypeList(token, {channel: settings.channel}),
      ),
  )

  const restCheckoutId =
    restCheckout && toGlobalId('Checkout', restCheckout.token)

  const [checkoutAddressesUpdate] = useMutation<
    CheckoutAddressesUpdate,
    CheckoutAddressesUpdateVariables
  >(checkoutAddressesUpdateMutation)

  const [checkoutBillingAddressUpdate] =
    useMutation<CheckoutBillingAddressUpdate>(
      checkoutBillingAddressUpdateMutation,
    )

  const [checkoutBillingAddressAnonymousUpdate] =
    useMutation<CheckoutBillingAddressAnonymousUpdate>(
      checkoutBillingAddressUpdateAnonymousMutation,
    )

  const [checkoutAddressesAnonymousUpdate] =
    useMutation<CheckoutAnonymousReceiverUpdate>(
      checkoutAnonymousReceiverUpdateMutation,
    )

  const [checkoutShippingMethodUpdate] =
    useMutation<CheckoutShippingMethodUpdate>(
      checkoutShippingMethodUpdateMutation,
    )
  const [checkoutPaymentCreate] = useMutation<
    CheckoutPaymentCreate,
    CheckoutPaymentCreateVariables
  >(checkoutPaymentCreateMutation)
  const [checkoutComplete] = useMutation<CheckoutComplete>(
    checkoutCompleteMutation,
  )

  const [checkoutCreate] = useMutation<CheckoutCreate>(checkoutCreateMutation)
  const [checkoutLinesUpdate] = useMutation<
    CheckoutLinesUpdate,
    CheckoutLinesUpdateVariables
  >(checkoutLinesUpdateMutation, {
    refetchQueries: user ? [{query: myCheckoutQuery}] : [],
  })

  const handleCheckoutError = useCallback(
    (err?: CheckoutLinesUpdate_checkoutLinesUpdate_checkoutErrors[]) => {
      const error = err?.[0]
      if (error) {
        if (
          error?.code === 'INSUFFICIENT_STOCK' &&
          error?.field === 'quantity'
        ) {
          const soldOutLine = restCheckout?.lines?.find((line) =>
            isOutOfStockWeb(line.variant?.inventoryStatus, onDutyFree),
          )
          if (soldOutLine) {
            toast.error(`Þessi vara er uppseld: ${soldOutLine.variant?.name}`)
          }
        } else {
          toast.error(checkoutErrorCodeMap[error?.code] || 'Óþekkt villa')
        }
      }
    },
    [restCheckout],
  )

  const sendCartToServer = useCallback(
    async (stateUpdate: Record<string, number>) => {
      let nextCheckout: RestCheckout
      let nextCheckoutId: string

      const currentState = getCartQuantityMap(nextCheckout)
      const stateUpdatePairs = toPairs(stateUpdate)
      const toUpdate = stateUpdatePairs.filter(
        ([vId, qty]) => currentState[vId] !== qty,
      )

      const addingToCart = stateUpdatePairs.find(
        ([vId, qty]) => !currentState[vId] && qty === 1,
      )

      if (user) {
        // Fetching existing or 'authenticated' checkout
        nextCheckout = await refetchRestCheckout()
        if (!nextCheckout) {
          // Creating new 'authenticated' checkout
          await checkoutCreate({
            variables: {email: user.email, channel: settings.channel},
          })
          nextCheckout = await refetchRestCheckout()
          if (!addingToCart) {
            return
          }
        }
      } else {
        // Fetching existing 'anonymous' checkout
        if (anonymousToken) {
          nextCheckout = await refetchRestCheckout()
          if (!nextCheckout) {
            // Creating new 'anonymous' checkout
            const checkout = await checkoutCreate({
              variables: {
                email: `${uuidv4()}@anonymous.saleor`,
                channel: settings.channel,
              },
            })
            nextCheckoutId = checkout.data.checkoutCreate.checkout.id
            setAnonymousToken(checkout.data.checkoutCreate.checkout.token)
            if (!addingToCart) {
              return
            }
          }
        } else {
          // Creating new 'anonymous' checkout
          const checkout = await checkoutCreate({
            variables: {
              email: `${uuidv4()}@anonymous.saleor`,
              channel: settings.channel,
            },
          })
          nextCheckoutId = checkout.data.checkoutCreate.checkout.id
          setAnonymousToken(checkout.data.checkoutCreate.checkout.token)
          if (!addingToCart) {
            return
          }
        }
      }

      const update = toUpdate.length
        ? (
            await checkoutLinesUpdate({
              variables: {
                checkoutId:
                  (nextCheckout &&
                    toGlobalId('Checkout', nextCheckout?.token)) ||
                  nextCheckoutId,
                lines: toUpdate.map(([variantId, quantity]) => ({
                  quantity,
                  variantId: toGlobalId('ProductVariant', variantId),
                })),
              },
            })
          )?.data?.checkoutLinesUpdate
        : undefined

      const error = update?.checkoutErrors?.[0]
      if (error) {
        let customErrorMsg: string
        if (error?.code === 'QUANTITY_GREATER_THAN_LIMIT') {
          const quantity = error?.message.match(/\d+/)
          customErrorMsg = `Einungis er hægt að setja ${quantity} stk. í körfu`
        }
        toast.error(
          customErrorMsg || checkoutErrorCodeMap[error?.code] || 'Óþekkt villa',
        )
        setCartState(prevCartState)
        setIsCartUpdating(false)
        return
      }

      const ToastWithLink = () => (
        <Link href="/karfa" passHref legacyBehavior>
          <ToastLink>Vara sett í körfu</ToastLink>
        </Link>
      )

      await refetchRestCheckout()
      if (addingToCart) {
        toast.success(ToastWithLink, {
          hideProgressBar: true,
          autoClose: 2000,
        })
      }

      setIsCartUpdating(false)
      return update
    },
    [
      checkoutLinesUpdate,
      anonymousToken,
      checkoutCreate,
      setAnonymousToken,
      refetchRestCheckout,
      user,
    ],
  )

  const debouncedSendCartToServer = useDebouncedCallback(sendCartToServer, 400)

  const cartUpdate = useCallback(
    async (stateUpdate: Record<string, number>, debounce = true) => {
      setIsCartUpdating(true)
      prevCartState = cartState
      setCartState({...cartState, ...stateUpdate})
      const update = debounce
        ? await debouncedSendCartToServer(stateUpdate)
        : await sendCartToServer(stateUpdate)
      return update
    },
    [cartState, setCartState, debouncedSendCartToServer, sendCartToServer],
  )

  const cartClear = useCallback(() => {
    cartUpdate(mapValues(cartState, () => 0))
  }, [cartUpdate, cartState])

  // Moves the anonymous cart (if it exists) to a user cart and removes the anonymous cart
  const moveAnonCartToUserCart = useCallback(
    async (token: string) => {
      await handleRestResponse(
        restApi.checkoutsMergeUpdate(
          'current',
          {
            token,
          },
          {channel: settings.channel},
        ),
      )
        .then(() => {
          removeAnonymousToken()
          refetchRestCheckout().then((res) => {
            setCartState(getCartQuantityMap(res))
          })
        })
        .catch((_err) => {
          removeAnonymousToken()
        })
    },
    [refetchRestCheckout, removeAnonymousToken],
  )

  const updateShippingMethod = useCallback(
    async (id: number) => {
      return checkoutShippingMethodUpdate({
        variables: {
          checkoutId: restCheckoutId,
          shippingMethodId: toGlobalId('ShippingMethod', id),
        },
      }).then((res) => {
        handleCheckoutError(
          res?.data?.checkoutShippingMethodUpdate?.checkoutErrors,
        )
        refetchRestCheckout()
      })
    },
    [
      checkoutShippingMethodUpdate,
      refetchRestCheckout,
      restCheckoutId,
      handleCheckoutError,
    ],
  )

  const updateAddresses = useCallback(
    async (shippingAddress: AddressInput, billingAddress: AddressInput) => {
      const res = await checkoutAddressesUpdate({
        variables: {
          checkoutId: restCheckoutId || '',
          shippingAddress,
          billingAddress,
        },
      })
      handleCheckoutError(
        res?.data?.checkoutBillingAddressUpdate?.checkoutErrors,
      )
      handleCheckoutError(
        res?.data?.checkoutShippingAddressUpdate?.checkoutErrors,
      )

      await refetchRestCheckout()

      return res?.data
    },
    [
      checkoutAddressesUpdate,
      refetchRestCheckout,
      restCheckoutId,
      handleCheckoutError,
    ],
  )

  const updateBillingAddress = useCallback(
    async (billingAddress: AddressInput) => {
      const res = await checkoutBillingAddressUpdate({
        variables: {
          checkoutId: restCheckoutId,
          billingAddress,
        },
      })
      handleCheckoutError(
        res?.data?.checkoutBillingAddressUpdate?.checkoutErrors,
      )

      await refetchRestCheckout()

      return res?.data
    },
    [
      checkoutBillingAddressUpdate,
      refetchRestCheckout,
      restCheckoutId,
      handleCheckoutError,
    ],
  )

  const updateBillingAddressAnonymous = useCallback(
    async (billingAddress: AddressInput, email: string) => {
      const res = await checkoutBillingAddressAnonymousUpdate({
        variables: {
          checkoutId: restCheckoutId,
          billingAddress,
          email,
        },
      })
      handleCheckoutError(
        res?.data?.checkoutBillingAddressUpdate?.checkoutErrors,
      )
      handleCheckoutError(res?.data?.checkoutEmailUpdate?.checkoutErrors)

      await refetchRestCheckout()

      return res?.data
    },
    [
      checkoutBillingAddressAnonymousUpdate,
      refetchRestCheckout,
      restCheckoutId,
      handleCheckoutError,
    ],
  )

  const updateAddressesAnonymous = useCallback(
    async (
      shippingAddress: AddressInput,
      billingAddress: AddressInput,
      email: string,
    ) => {
      if (!shippingAddress.lastName) {
        shippingAddress.lastName = EMPTY_SSN
      }
      if (!billingAddress.lastName) {
        billingAddress.lastName = EMPTY_SSN
      }
      const res = await checkoutAddressesAnonymousUpdate({
        variables: {
          checkoutId: restCheckoutId,
          shippingAddress,
          billingAddress,
          email,
        },
      })

      const checkout = await refetchRestCheckout()

      if (onDutyFree) {
        const availShippingId =
          checkout?.availableShippingMethods?.[0]?.shippingMethod?.id
        availShippingId && (await updateShippingMethod(availShippingId))
      }
      return res?.data
    },
    [
      restCheckoutId,
      refetchRestCheckout,
      updateShippingMethod,
      checkoutAddressesAnonymousUpdate,
    ],
  )

  const updateDeliverySlot = useCallback(
    async (slotData: CheckoutDeliveryRequest) => {
      return handleRestResponse(
        restApi.checkoutsDeliveryCreate(restCheckout?.token, slotData, {
          channel: settings.channel,
        }),
      ).then((res) => {
        refetchRestCheckout(res)
      })
    },
    [restCheckout, refetchRestCheckout],
  )

  const createPayment = useCallback(
    async (input: PaymentInput) => {
      return checkoutPaymentCreate({
        variables: {
          checkoutId: restCheckoutId,
          input,
        },
      }).then((res) => res?.data?.checkoutPaymentCreate)
    },
    [checkoutPaymentCreate, restCheckoutId],
  )

  const completeCheckout = useCallback(
    async (paymentData: string) => {
      return checkoutComplete({
        variables: {
          checkoutId: restCheckoutId,
          paymentData,
        },
      }).then((res) => {
        if (
          !res?.data?.checkoutComplete?.confirmationNeeded &&
          !res?.data?.checkoutComplete?.checkoutErrors?.length
        ) {
          removeAnonymousToken()
        }
        return res?.data?.checkoutComplete
      })
    },
    [checkoutComplete, removeAnonymousToken, restCheckoutId],
  )

  const completeDutyFreeCheckout = useCallback(async () => {
    return checkoutComplete({
      variables: {
        checkoutId: restCheckoutId,
      },
    }).then((res) => {
      if (!res?.data?.checkoutComplete?.checkoutErrors?.length) {
        removeAnonymousToken()
      }
      return res?.data?.checkoutComplete
    })
  }, [checkoutComplete, restCheckoutId, removeAnonymousToken])

  const completeDutyFreeOrder = useCallback(
    async (flightNumber: string, flightDate: Date) => {
      await handleRestResponse(
        restApi.dutyfreeCheckoutsUpdate(restCheckout?.token, {
          flightNumber,
          flightDate: formatISO(flightDate, {representation: 'date'}),
        }),
      )

      const payment = await createPayment({
        gateway: 'elko.payments.dutyfree',
        token: restCheckout?.token,
      })
      const paymentError = payment?.paymentErrors?.[0]
      if (paymentError) {
        toast.error(checkoutOrPaymentError(paymentError) || 'Pöntun mistókst')
        return
      }

      const checkoutCompleteData = await completeDutyFreeCheckout()
      const checkoutError = checkoutCompleteData?.checkoutErrors?.[0]
      if (checkoutError) {
        toast.error(checkoutOrPaymentError(checkoutError) || 'Pöntun mistókst')
        return
      }
      const orderToken = checkoutCompleteData?.order?.token

      if (orderToken) {
        return orderToken
      }
    },
    [completeDutyFreeCheckout, createPayment, restCheckout],
  )

  const updateInsurances = useCallback(
    async (variantSku: string, insurance: AvailableInsurance, qty: number) => {
      type InsuranceMap = {
        [key: string]: AddInsuranceRequest[]
      }
      const insuranceMap: InsuranceMap = fromPairs(
        restCheckout?.lines?.map((line) => [
          line?.variant?.sku,
          line?.insurances?.map((i) => ({
            variantSku: line?.variant?.sku,
            insuranceSku: i.sku,
          })),
        ]),
      )

      insuranceMap[variantSku] =
        insurance?.months === 0
          ? []
          : times(qty, () => ({variantSku, insuranceSku: insurance.sku}))

      const updatedInsurances: AddInsuranceRequest[] = flatten(
        values(insuranceMap),
      )
      return handleRestResponse(
        restApi.checkoutsInsuranceUpdate(
          restCheckout?.token,
          updatedInsurances,
          {
            channel: settings.channel,
          },
        ),
      ).then((res) => {
        refetchRestCheckout(res)
      })
    },
    [restCheckout, refetchRestCheckout],
  )

  const setCheckoutNoteMsg = useCallback(
    async (noteMsg: string) => {
      return handleRestResponse(
        restApi.checkoutsPartialUpdate(
          restCheckout?.token || '',
          {
            note: noteMsg,
          },
          {channel: settings.channel},
        ),
      )
    },
    [restCheckout],
  )

  useEffect(() => {
    if (
      user &&
      !missingAttributes &&
      anonymousToken &&
      restCheckout &&
      !restCheckoutIsValidating
    ) {
      moveAnonCartToUserCart(anonymousToken)
    }
  }, [
    user,
    restCheckout,
    anonymousToken,
    missingAttributes,
    restCheckoutIsValidating,
    moveAnonCartToUserCart,
  ])

  useEffect(() => {
    const initCartState = () => {
      setCartState(getCartQuantityMap(restCheckout))
    }
    if (!initializedCart && restCheckout?.lines?.length) {
      initializedCart = true
      initCartState()
    }
  }, [restCheckout])

  const hasInsurableLines = restCheckout?.lines.some(
    (line) =>
      restCheckout.availableInsurances?.some(
        (insurance) =>
          insurance.sku === line.variant.sku && insurance.insurance.length,
      ) && !line.insurances.length,
  )

  const hasInsurances = restCheckout?.lines.some(
    (line: CheckoutLine) => !!line.insurances.length,
  )

  const shippingMethodChannelListing =
    restCheckout?.availableShippingMethods?.find(
      (sm) => sm.shippingMethod.id === restCheckout?.shippingMethod,
    )

  return (
    <CheckoutContext.Provider
      value={{
        restCheckout,
        restCheckoutLoading,
        restCheckoutIsValidating,
        restCheckoutId,
        cartState:
          cartState == null ? getCartQuantityMap(restCheckout) : cartState,
        salesType,
        salesTypeLoading,
        cartUpdate,
        cartClear,
        cartIsUpdating,
        updateAddresses,
        updateBillingAddress,
        updateShippingMethod,
        createPayment,
        completeCheckout,
        updateInsurances,
        hasInsurableLines,
        hasInsurances,
        shippingMethodChannelListing,
        updateDeliverySlot,
        updateAddressesAnonymous,
        updateBillingAddressAnonymous,
        anonymousToken,
        refetchRestCheckout,
        removeAnonymousToken,
        completeDutyFreeOrder,
        setCheckoutNoteMsg,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export function useCheckout() {
  return useContext(CheckoutContext) as CheckoutContextProps
}
