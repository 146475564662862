/* eslint-disable @typescript-eslint/no-explicit-any */
import {restApi} from '.'
import useSWR from 'swr'
import type {SWRConfiguration, Key} from 'swr'

import {
  ApiFnProps,
  AsyncReturnType,
  HttpResponseData,
  HttpResponseError,
} from './types'

export function useSaleorRestSWR<
  T extends keyof ApiFnProps,
  Ret extends AsyncReturnType<ApiFnProps[T]>,
  Data extends HttpResponseData<Ret>,
  Error extends HttpResponseError<Ret>,
  FetcherFactory = (m: ApiFnProps[T], k: Key) => ReturnType<ApiFnProps[T]>,
>(
  apiMethodName: T,
  key: Key = 'key',
  fetcherFactory?: FetcherFactory,
  conf?: SWRConfiguration<Data, Error>,
) {
  const fetcher = async (...args: any): Promise<Data> => {
    const origApiMethod = restApi[apiMethodName] as any

    const apiMethod: any = fetcherFactory
      ? (fetcherFactory as any)(origApiMethod, key)
      : () => origApiMethod()

    const {data, error} = await apiMethod(...args).catch((r) => r)
    if (error) {
      throw error
    }
    return data
  }

  return useSWR<Data, HttpResponseError<Ret>>(key, fetcher, {
    revalidateOnFocus: false,
    ...(conf || {}),
  })
}
