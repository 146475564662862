import {useState} from 'react'

import {RemixiconReactIconProps} from 'remixicon-react'
import styled from 'styled-components'

const colorMap = {
  green:
    'invert(81%) sepia(24%) saturate(792%) hue-rotate(86deg) brightness(89%) contrast(93%)',
  lightBlue:
    'invert(73%) sepia(12%) saturate(1838%) hue-rotate(167deg) brightness(98%) contrast(93%)',
}

const SVGImage = styled.img`
  filter: ${colorMap.lightBlue};
`

interface Props extends RemixiconReactIconProps {
  icon: string
  size: number
  color?: 'green' | 'lightBlue'
}

export default function DynamicIcon({
  icon,
  size,
  color = 'lightBlue',
}: Props): JSX.Element | null {
  const [src, setSrc] = useState<string | null>(`/icons/${icon}.svg`)

  if (src === null) {
    return null
  }

  return (
    <SVGImage
      src={src}
      alt={icon}
      width={size}
      height={size}
      style={{filter: colorMap[color]}}
      className={'remixicon-icon'}
      onError={() => setSrc(null)}
    />
  )
}
