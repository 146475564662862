import {styledTheme} from '@festi/common/themes'
import {css, keyframes} from 'styled-components'

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`

export function mainButtonCommonStyles() {
  return css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: ${styledTheme.cursor.pointer};
    border: 1px solid transparent;
    -webkit-tap-highlight-color: transparent;
    &.primary {
      background-color: ${styledTheme.palette.blue};
      color: ${styledTheme.palette.white};
    }
    &.primary:hover&:not(:disabled):not(:focus),
    &.primary:focus-visible&:not(:disabled) {
      background-color: ${styledTheme.palette.ui90Solid};
    }
    &.secondary {
      background-color: ${styledTheme.palette.green};
      color: ${styledTheme.palette.blue};
    }
    &.secondary:hover&:not(:disabled):not(:focus),
    &.secondary:focus-visible&:not(:disabled) {
      background-color: ${styledTheme.palette.pink};
    }
    &.negative {
      background-color: ${styledTheme.palette.white};
      color: ${styledTheme.palette.blue};
    }
    &.negative:hover&:not(:disabled):not(:focus),
    &.negative:focus-visible&:not(:disabled) {
      border-color: ${styledTheme.palette.ui20Solid};
      background-color: ${styledTheme.palette.ui10Solid};
    }
    &.ghost {
      background-color: transparent;
      color: ${styledTheme.palette.blue};
      border-color: ${styledTheme.palette.grey};
    }
    &.ghost:hover&:not(:disabled):not(:focus),
    &.ghost:focus-visible&:not(:disabled) {
      background-color: ${styledTheme.palette.ui5Solid};
      border-color: ${styledTheme.palette.ui20Solid};
    }
    &.ghostNegative {
      background-color: transparent;
      color: ${styledTheme.palette.white};
      border-color: ${styledTheme.palette.white};
    }
    &.ghostNegative:hover&:not(:disabled):not(:focus),
    &.ghostNegative:focus-visible&:not(:disabled) {
      background-color: ${styledTheme.palette.ui5Solid};
      border: 1px solid ${styledTheme.palette.ui10Solid};
    }
    &:disabled&:not(.loading) {
      opacity: 0.4;
      cursor: not-allowed;
    }
    &:focus {
      outline: none;
    }
    &.loading {
      animation: ${fadeOut} 1.5s ease-in-out infinite alternate;
      cursor: not-allowed;
    }
  `
}
