import styled from 'styled-components'
import {palette} from 'styled-tools'

import {fluidRange} from '../../utils/styles'

const CardTitle = styled.span`
  font-family: 'ElkjopSans', sans-serif;
  line-height: 1.2;
  font-weight: 500;
  color: ${palette('blue')};
  text-align: left;
  ${fluidRange('font-size', `${1 * 16}px`, `${1.125 * 16}px`)}
`

export default CardTitle
