import {VariantList, VariantDetail, Variant} from '../api/rest'
import settings from '../constants/settings'

export function getListings(productVariant: VariantDetail | VariantList) {
  return productVariant?.listings?.[settings.channel]
}

export function getListingPrice(
  productVariant?: VariantDetail | VariantList | Variant,
) {
  return productVariant?.listings?.[settings.channel]?.price
}
